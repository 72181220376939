export { default as storage } from "./storage";

export const env = (name) => {
    const renowned = `REACT_APP_${name}`;
    return process.env[renowned];
}

const CACHE_KEY = 'app';
export const cache = {
    get: (field) => {
        const app = localStorage.getItem(CACHE_KEY);
        if (!app) {
            return undefined;
        } else {
            const data = JSON.parse(app);
            return data[field];
        }
    },
    set: (field, data) => {
        const app = localStorage.getItem(CACHE_KEY);
        if (!app) {
            const cached = {};
            cached[field] = data;
            localStorage.setItem(CACHE_KEY, JSON.stringify(cached));
        } else {
            const cached = JSON.parse(app);
            cached[field] = data;
            localStorage.setItem(CACHE_KEY, JSON.stringify(cached));
        }
    },
    delete: (field) => {
        const app = localStorage.getItem(CACHE_KEY);
        if (app) {
            const cached = JSON.parse(app);
            delete cached[field];
            localStorage.setItem(CACHE_KEY, JSON.stringify(cached));
        }
    }
}

export const console = {
    log: function () {
        console.log.apply(this, arguments);
    },
    error: function () {
        console.error.apply(this, arguments);
    }
}