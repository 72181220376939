import { useEffect, useRef } from "react";
import NeoColumn from "design/design_components/neo/layout/NeoColumn.base";
import NeoCard from "design/design_components/neo/panel/NeoCard.base";
import NeoMessages from "design/design_components/neo/messages/NeoMessages.base";
import NeoInnerTitle from "design/design_components/neo/title/NeoInnerTitle.base";
import NeoButtonMain from "design/design_components/neo/button/NeoButtonMain.base";
import NeoButtonOutlined from "design/design_components/neo/button/NeoButtonOutlined.base";
import { useHistory } from "react-router-dom";
import LoginFormComponent from "./components/Login.form.component";
import ROUTES from "routers/Routes.router.json";
import { useComponent, useGeneral, useUser } from "hooks";
import * as sessionsService from "services/sessions.service";
import { APP } from "../../constants";
import NeoButtonText from "design/design_components/neo/button/NeoButtonText.base";

const LoginView = (_props) => {
    const user = useUser();
    const general = useGeneral();
    const component = useComponent({
        data: {
            sessionId: null,
            continueUrl: null,
            isLoading: false
        }
    });

    const history = useHistory();
    const messageLoginForm = useRef(null);

    const fnSignIn = component.useAsync(user.signIn);
    const fnCreateToken = component.useAsync(sessionsService.createToken);

    component.useEffect(() => {
        const redirect = async () => {
            if (user.isSignIn()) {
                if (general.data.continueUrl && general.data.sessionId) {
                    const { token } = await fnCreateToken({
                        id: general.data.sessionId
                    });

                    const continueUrl = new URL(general.data.continueUrl);
                    continueUrl.searchParams.set('token', token);
                    window.location.replace(continueUrl);
                } else {
                    const rootPathUser = user.getIndexPath();
                    history.push(rootPathUser);
                }
            }
        }

        redirect().catch(console.error);
    }, [user.data])

    const loginFormHandleSubmit = async (_params) => {
        messageLoginForm?.current?.clear();

        component.data.isLoading = true;
        try {
            await fnSignIn({
                email: _params.email,
                password: _params.password
            });
        } catch (error) {
            switch (error.code) {
                case 'auth/invalid-email':
                    messageLoginForm?.current?.show({
                        severity: "error",
                        summary: "",
                        detail: `El valor que se proporcionó para la propiedad del usuario email no es válido.`,
                        life: 4000
                    });
                    break;
                case 'auth/user-not-found':
                case 'auth/wrong-password':
                case 'auth/invalid-password':
                    messageLoginForm?.current?.show({
                        severity: "error",
                        summary: "",
                        detail: `Error al iniciar sesión, verifica tu usuario y contraseña`,
                        life: 4000
                    });
                    break;
                case 'auth/user-disabled':
                    messageLoginForm?.current?.show({
                        severity: "error",
                        summary: "",
                        detail: `Por seguridad, tu cuenta se encuentra deshabilitada, da clic en "¿Olvidaste tu contraseña?", para cambiar tu contraseña y volver a habilitar tu cuenta, si necesitas más detalles contacta a tu DN.`,
                        life: 8000
                    });
                    break;
                case 'auth/internal-error':
                    messageLoginForm?.current?.show({
                        severity: "error",
                        summary: "",
                        detail: `El servidor de Authentication encontró un error inesperado cuando se intentaba procesar la solicitud. Si el error persiste, contacta a tu DN para solucionarlo.`,
                        life: 8000
                    });
                    console.log(error);
                    break;
                default:
                    messageLoginForm?.current?.show({
                        severity: "error",
                        summary: "",
                        detail: `El servidor de Authentication encontró un error inesperado cuando se intentaba procesar la solicitud. Si el error persiste, contacta a tu DN para solucionarlo.`,
                        life: 8000
                    });
                    console.log(error);
                    break;
            }
            component.data.isLoading = false;
        }
        if (general.data.continueUrl === null && general.data.sessionId === null) {
            component.data.isLoading = false;
        }
    }

    return (<>
        <NeoCard extra="text-align-center">

            <LoginFormComponent
                onSubmit={loginFormHandleSubmit}
                loading={component.isLoading || component.data.isLoading}
            />

            {(component.isLoading === false && component.data.isLoading === false) &&
                <NeoButtonText
                    onClick={() => {
                        history.push(ROUTES.password.recovery);
                    }}
                >
                    ¿Olvidaste tu contraseña?
                </NeoButtonText>
            }

            <NeoMessages ref={messageLoginForm} />

        </NeoCard>

        {(general.data.app === APP.NEO_VALIDADOR.NAME) &&
            <NeoCard>
                <NeoColumn
                    extra="text-align-center">
                    <NeoInnerTitle
                        extra="primero text-align-center"
                    >
                        ¿No tienes cuenta
                        {general.data.app &&
                            ` en ${general.data.app}`
                        }
                        ?
                    </NeoInnerTitle>


                    <NeoButtonMain
                        extra=""
                        label="Regístrate ahora"
                        onClick={() => {
                            history.push(ROUTES.register);
                        }} />
                </NeoColumn>
            </NeoCard>
        }

        {general.data.app === APP.NEO_VALIDADOR.NAME &&
            <NeoColumn
                extra="text-align-center white"
            >
                <p>¿Aún no te has migrado a la nueva versión de NeoValidador?</p>
                <a href="https://neovalidador.com/v1/">
                    <NeoButtonOutlined
                        extra="button-inverted"
                        label="Inicio de sesión en NeoValidador clásico"
                    />
                </a>
            </NeoColumn>
        }
    </>)
}

export default LoginView;