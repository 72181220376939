import utils from "utils";
import * as sessionsService from "./sessions.service";

export const list = async (data) => {
    try {
        const authorization = await sessionsService.getToken();

        const result = await utils.http.request({
            url: `${utils.app.env('API_HOST_URL_BASE')}/api/v1/reviews/list`,
            method: 'post',
            headers: {
                'Authorization': authorization
            },
            data
        });

        return result?.data;
    } catch (error) {
        throw error;
    }
}

export const register = async (data) => {
    try {
        const authorization = await sessionsService.getToken();

        const result = await utils.http.request({
            url: `${utils.app.env('API_HOST_URL_BASE')}/api/v1/reviews/register`,
            method: 'post',
            headers: {
                'Authorization': authorization
            },
            data
        });

        return result?.data;
    } catch (error) {
        throw error;
    }
}