import { createContext, useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import * as firebase from "services/firebase.service";
import * as sessionsService from "services/sessions.service";
import { useComponent } from "hooks";

const UserContext = createContext({});

export const UserContextProvider = (_props) => {
    const componente = useComponent({
        data: {
            loading: true,
            loggins: 0,
            logouts: 0
        }
    });

    const [user, setUser] = useState();
    const [userAuth, setUserAuth] = useState(() => {
        return firebase.auth?.currentUser;
    });

    useEffect(() => {
        onAuthStateChanged(firebase.auth, _userAuth => {
            if (!_userAuth) {
                componente.data.loading = false;
            }

            setUserAuth(_userAuth);
        });
    }, [])

    useEffect(() => {
        if (userAuth) {
            componente.data.loggins++;
            loadDataUser();
        } else {
            if (componente.data.loggins > 0) {
                componente.data.logouts++;
                setUser();
            }
        }
    }, [userAuth])

    const loadDataUser = async () => {
        if (!userAuth) {
            return null;
        }

        const { user } = await sessionsService.getInfo();
        user.uid = userAuth.uid;

        setUser(user);

        componente.data.loading = false;

        return user;
    }

    return (
        <UserContext.Provider value={{
            data: componente.data,
            auth: firebase.auth,
            userAuth,
            user,
            loadDataUser
        }}>
            {_props.children}
        </UserContext.Provider>
    )
}

export default UserContext;