export const email = (email) => {
    const regex = /^(?:(?:[\w`%&*\-=+:'?/]+(?:(?:\.(?:"(?:\\?[\w`%&*\-=+:'?/.()<>[\] @]|\\"|\\\\)*"|[\w`%&*\-=+:'?/]+))*\.[\w`%&*\-=+:'?/]+)?)|(?:"(?:\\?[\w`%&*\-=+:'?/.()<>[\] @]|\\"|\\\\)+"))@(?:[a-zA-Z\d-]+(?:\.[a-zA-Z\d-]+)*|\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])$/;
    return regex.test(email);
}

export const emailFreeDomain = (email) => {
    const FREE_EMAIL_DOMAINS = [
        'gmail',
        'yahoo',
        'outlook',
        'hotmail',
        'icloud',
        'aol',
        'zoho',
        'gmx',
        'proton',
        'tutanota'
    ];

    try {
        const resultMatch = email.match(/@([a-zA-Z]+)/);
        if (resultMatch === null) {
            return true;
        }

        const domain = resultMatch[1];
        const domainIsFreeDomain = FREE_EMAIL_DOMAINS.includes(domain);
        return domainIsFreeDomain;
    } catch (error) {
        return false;
    }
}

export const URL = (url) => {
    try {
        return Boolean(new window.URL(url));
    } catch (_) {
        return false;
    }
}

export const telephone = (telephone) => {
    const regex = /^[0-9]{10}$/;
    return regex.test(telephone);
}

export const string = {
    isEmpty: (str) => {
        return (!str || /^\s*$/.test(str));
    }
}

export const isFNPromise = (fn) => {
    if (fn instanceof (async () => { }).constructor === true) {
        return true;
    }

    return false;
}

export const fn = (fn) => {
    if (typeof fn === 'function') {
        return true;
    }

    return false;
}

export const permissions = (permissions, permission) => {
    let allow = permission.split('.').reduce((prev, curr) => {
        if (prev === true) {
            return true;
        }

        return typeof prev === 'object' ? prev[curr] : false;
    }, permissions);

    if (typeof allow !== 'boolean') {
        throw new Error('The permission is not valid');
    }

    return allow;
}