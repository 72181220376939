const DEFAULT = {
    LOCALE: 'es-MX',
    CURRENCY: 'MXN'
}

export const toCurrency = (value, locale = DEFAULT.LOCALE, currency = DEFAULT.CURRENCY) => {
    value = String(value).replace(/[^0-9.-]/g, '');
    if (value === '') {
        return '';
    }

    return Number(value).toLocaleString(locale, { currency });
}