import 'design/assets/styles/adm/style.scss';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';

import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import { AppContextProvider } from 'contexts/app/App.context';
import { TriggerContextProvider } from 'contexts/Trigger.context';
import { UserContextProvider } from 'contexts/User.context';
import RootBase from 'bases/Root.base';
import MainRouter from 'routers/Main.router';
import './App.scss';

function App() {
    return (
        <AppContextProvider>
            <TriggerContextProvider>
                <UserContextProvider>
                    <RootBase>
                        <MainRouter />
                    </RootBase>
                </UserContextProvider>
            </TriggerContextProvider>
        </AppContextProvider>
    );
}

export default App;
