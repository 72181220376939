import CustomRouter, { Switch } from 'routers/Custom.router';
import { useUser } from 'hooks';

import ROUTES from 'routers/Routes.router.json';

/* Routes */
import AdminRouter from 'routers/Admin.router';
import CustomerRouter from 'routers/Customer.router';

/* Views */
import UserBase from 'bases/User.base';

const UserRouter = () => {
    const user = useUser();

    return (
        <UserBase>
            <Switch>

                {/* Admin */}
                <CustomRouter
                    exact={false}
                    path={ROUTES.admin.index}
                    redirect={user.isEmployed}
                    redirectValue={false}
                    component={AdminRouter}
                />

                {/* Customer */}
                <CustomRouter
                    exact={false}
                    path={ROUTES.customer.index}
                    redirect={user.isCustomer}
                    redirectValue={false}
                    component={CustomerRouter}
                />

            </Switch>
        </UserBase>
    )
}

export default UserRouter;