import { useContext, useEffect } from "react";
import TriggerContext from 'contexts/Trigger.context';

const useTrigger = () => {
    const trigger = useContext(TriggerContext);
    const listeners = [];

    useEffect(() => {
        if (typeof trigger.event === 'object') {
            if (Object.keys(trigger.event).length !== 0) {
                for (const listener of listeners) {
                    if (listener.name === trigger.event.name) {
                        if (typeof listener.fn === 'function') {
                            listener.fn(trigger.event?.data);
                        }
                    }
                }
            }
        }
    }, [trigger.event])

    const listener = (fn, name) => {
        if (typeof fn === 'function' && typeof name === 'string') {
            listeners.push({
                fn,
                name
            });
        }
    }

    const event = (name, options = {}) => {
        const event = { name };

        if (options.data !== undefined) {
            event.data = options.data;
        }

        trigger.setEvent(event);
    }

    return {
        history: trigger.history,
        clear: trigger.clear,
        listener,
        event
    }
}

export default useTrigger;