import { useRef } from "react";
import NeoCard from "design/design_components/neo/panel/NeoCard.base";
import NeoMessages from "design/design_components/neo/messages/NeoMessages.base";
import { useHistory } from "react-router-dom";
import ROUTES from "routers/Routes.router.json";
import { useComponent, useUser } from "hooks";
import PasswordRecoveryFormComponent from "./components/Password.recovery.form.component";
import NeoButtonText from "design/design_components/neo/button/NeoButtonText.base";

const PasswordRecoveryView = (_props) => {
    const user = useUser();
    const component = useComponent();

    const history = useHistory();
    const messageLoginForm = useRef(null);

    const fnRecoveryPassword = component.useAsync(user.recoveryPassword);

    const recoveryPasswordFormHandleSubmit = async (_params) => {
        messageLoginForm?.current?.clear();

        try {
            await fnRecoveryPassword(_params.email);
            messageLoginForm?.current?.show({
                severity: "success",
                summary: "",
                detail: `Se ha enviado el enlace para restablecer la contraseña a tu correo`,
                life: 10000
            });
        } catch (error) {
            switch (error.code) {
                case 'auth/user-not-found':
                    messageLoginForm?.current?.show({
                        severity: "error",
                        summary: "",
                        detail: `Verifica tu correo o intenta de nuevo`,
                        life: 4000
                    });
                    return
                default:
                    console.log(error);
                    break;
            }
        }
    }

    return (
        <NeoCard extra="text-align-center">
            <PasswordRecoveryFormComponent
                onSubmit={recoveryPasswordFormHandleSubmit}
                loading={component.isLoading}
            />

            {(component.isLoading) === false &&
                <NeoButtonText
                    onClick={() => {
                        history.push(ROUTES.login);
                    }}
                >
                    Regresar al inicio de sesión
                </NeoButtonText>
            }

            <NeoMessages ref={messageLoginForm} />
        </NeoCard>
    )
}

export default PasswordRecoveryView;