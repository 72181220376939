import utils from "utils";

export const register = async (data) => {
    try {
        const result = await utils.http.request({
            url: `${utils.app.env('API_HOST_URL_BASE')}/api/v1/users/register`,
            method: 'post',
            data
        });

        return result?.data;
    } catch (error) {
        throw error;
    }
}