import NeoColumn from "design/design_components/neo/layout/NeoColumn.base"
import NeoInnerTitle from "design/design_components/neo/title/NeoInnerTitle.base"
import NeoInputText from "design/design_components/neo/form/NeoInputText.base"
import NeoButtonSection from "design/design_components/neo/layout/NeoButtonSection.base"
import NeoButtonMain from "design/design_components/neo/button/NeoButtonMain.base"
import { useComponent } from "hooks";
import utils from "utils"

const PasswordRecoveryFormComponent = (_props) => {
    const component = useComponent({
        data: {
            email: '',

            errorEmail: '',

            formIsInvalid: true,

            sendButtonWasPressed: false
        }
    });

    component.useEffect(() => {
        if (component.data.sendButtonWasPressed) {
            handleValidation();
        }
    }, [
        component.data.email
    ]);

    const handleValidation = () => {
        component.data.errorEmail = utils.validate.email(component.data.email)
            ? ''
            : 'El correo no tiene formato válido';

        if (
            component.data.errorEmail === ''
        ) {
            return true;
        }

        return false;
    }

    const handleSubmit = () => {
        if (typeof _props.onSubmit === 'function') {
            component.data.sendButtonWasPressed = true;
            if (handleValidation()) {
                _props.onSubmit({
                    email: component.data.email
                });
            }
        }
    }

    const onKeyDown = (event) => {
        if (event.key === "Enter") {
            handleSubmit();
        }
    }

    return (
        <NeoColumn col={12} extra="px-0">
            <NeoInnerTitle
                extra="primero"
            >
                Recuperación de cuenta
            </NeoInnerTitle>

            <NeoInputText
                label="Correo electrónico"
                value={component.data.email}
                onChange={(event) => {
                    component.data.email = event.target.value;
                }}
                error={component.data.errorEmail}
                onKeyDown={onKeyDown}
                disabled={_props.loading}
            />

            <NeoButtonSection>
                <NeoButtonMain
                    label="Enviar"
                    onClick={handleSubmit}
                    disabled={_props.loading}
                    loading={_props.loading}
                />
            </NeoButtonSection>
        </NeoColumn>
    )
}

export default PasswordRecoveryFormComponent;