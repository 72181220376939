import { createContext, useState, useEffect } from "react";

const TriggerContext = createContext({});

export const TriggerContextProvider = (_props) => {
    const [history, setHistory] = useState([]);
    const [event, setEvent] = useState({});

    useEffect(() => {
        if (typeof event === 'object') {
            if (Object.keys(event).length !== 0) {
                setHistory((events) => [
                    ...events,
                    event
                ])
                setEvent({});
            }
        }
    }, [event])

    const clear = () => {
        setHistory([]);
        setEvent({});
    }

    return (
        <TriggerContext.Provider value={{
            history,
            event,
            setEvent,
            clear
        }}>
            {_props.children}
        </TriggerContext.Provider>
    )
}

export default TriggerContext;