import { useRef } from "react";
import NeoCard from "design/design_components/neo/panel/NeoCard.base";
import NeoMessages from "design/design_components/neo/messages/NeoMessages.base";
import { useComponent, useGeneral, useUser } from "hooks";
import PasswordResetFormComponent from "./components/Password.reset.form.component";
import { useHistory } from "react-router-dom";
import ROUTES from "routers/Routes.router.json";

const PasswordRecoveryView = (_props) => {
    const user = useUser();
    const component = useComponent({
        hashResetPassword: null
    });
    const general = useGeneral();

    const history = useHistory();

    const messageLoginForm = useRef(null);

    const fnResetPassword = component.useAsync(user.resetPassword);

    component.useEffect(() => {
        const verifyHash = async (_hash) => {
            const isValid = await user.verifyHashResetPassword(_hash);
            if (isValid) {
                general.data.hashResetPassword = _hash;
            } else {
                history.push(ROUTES.index);
            }
        }

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        const paramOobCode = urlParams.get('oobCode');
        if (!paramOobCode) {
            history.push(ROUTES.index);
        } else {
            verifyHash(paramOobCode).catch((error) => {
                history.push(ROUTES.index);
            });
        }
    }, [])

    const recoveryPasswordFormHandleSubmit = async (_params) => {
        messageLoginForm?.current?.clear();

        try {
            await fnResetPassword(general.data.hashResetPassword, _params.password);
            messageLoginForm?.current?.show({
                severity: "success",
                summary: "",
                detail: `Contraseña cambiada con éxito, puedes iniciar sesión`,
                life: 10000
            });
            history.push(ROUTES.index);
        } catch (error) {
            switch (error.code) {
                case 'auth/weak-password':
                    messageLoginForm?.current?.show({
                        severity: "error",
                        summary: "",
                        detail: `Tu contraseña debe ser: mayor a 8 caracteres, incluir una letra mayúscula, una minúscula, un número y un caracter especial`,
                        life: 4000
                    });
                    return
                default:
                    console.log(error);
                    break;
            }
        }
    }

    return (<>
        {general.data.hashResetPassword && (
            <NeoCard extra="text-align-center">
                <PasswordResetFormComponent
                    onSubmit={recoveryPasswordFormHandleSubmit}
                    loading={component.isLoading}
                />
                <NeoMessages ref={messageLoginForm} />
            </NeoCard>
        )}
    </>)
}

export default PasswordRecoveryView;