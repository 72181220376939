import { cloneElement } from 'react';
import { Route, Redirect, BrowserRouter, Switch, useLocation } from 'react-router-dom';
import ROUTES from 'routers/Routes.router.json';

const isRedirect = (_redirect) => {
    let isRedirect = false;

    switch (typeof _redirect) {
        case 'function':
            isRedirect = Boolean(_redirect());
            break;
        case 'boolean':
            isRedirect = _redirect;
            break;
        case 'object':
            isRedirect = Object.entries(_redirect).length !== 0;
            break;
        default:
            break;
    }

    if (Array.isArray(_redirect)) {
        _redirect.forEach(verifyFn => {
            if (typeof verifyFn === 'function') {
                if (verifyFn()) {
                    isRedirect = true;
                }
            } else if (verifyFn) {
                isRedirect = true;
            }
        });
    }

    return isRedirect;
}

const toPath = (_toPath) => {
    switch (typeof _toPath) {
        case 'function':
            return _toPath();
        case 'string':
            return _toPath;
        default:
            return ROUTES.index;
    }
}

const CustomRouter = (_props) => {
    return (
        <Route exact={_props.exact} path={_props.path} render={(routeProps) => {

            /* Redirect */
            if (_props?.redirect !== undefined) {
                if (Boolean(isRedirect(_props.redirect)) === Boolean(_props.redirectValue)) {
                    const path = _props.redirectToPath
                        ? _props.redirectToPath
                        : ROUTES.index;

                    return <Redirect to={{ pathname: toPath(path), state: { from: routeProps.location } }} />;
                }
            }

            /* Load children | component */
            if (_props.children || _props.component) {
                let component;

                if (_props.children) {
                    component = cloneElement(_props.children, { ...routeProps });
                } else if (_props.component) {
                    component = <_props.component {...routeProps} />;
                }

                if (typeof _props.onLoad === 'function') {
                    _props.onLoad(routeProps);
                }

                return component;
            }

            return null;
        }} />
    );
}

CustomRouter.defaultProps = {
    exact: true,
    redirectValue: true
}

export { BrowserRouter, Switch, useLocation };

export default CustomRouter;