import { useUser } from "hooks";
import LoadingCommon from "components/commons/loading/Loading.common";

const RootBase = (_props) => {
    const user = useUser();

    return user.data.loading
        ? null
        : _props.children;
}

export default RootBase;