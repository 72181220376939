import { useRef, forwardRef, useImperativeHandle } from "react";
import { DateTime } from "luxon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faFileCode } from "@fortawesome/free-regular-svg-icons";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import NeoDialog from "design/design_components/neo/overlay/NeoDialog.base";
import NeoButtonMain from "design/design_components/neo/button/NeoButtonMain.base";
import NeoInputCalendar from "design/design_components/neo/form/NeoCalendar.base";
import NeoInputText from "design/design_components/neo/form/NeoInputText.base";
import NeoInputNumber from "design/design_components/neo/form/NeoInputNumber.base";
import NeoInputTextarea from "design/design_components/neo/form/NeoInputTextarea.base";
import NeoInputFile from "design/design_components/neo/form/NeoInputFile.base";
import NeoGridContainer from "design/design_components/neo/layout/NeoGridContainer.base";
import NeoColumn from "design/design_components/neo/layout/NeoColumn.base";
import { useComponent } from "hooks";
import utils from "utils";

const AdminCustomerOrderFactureModalFormComponent = (_props) => {
    const fileXML = useRef();
    const filePDF = useRef();
    const component = useComponent({
        data: {
            factureDate: null,
            factureInvoice: null,
            factureComment: '',

            errorFactureDate: '',
            errorFactureInvoice: '',
            errorFactureComment: '',
            errorFactureFilePDF: '',
            errorFactureFileXML: '',

            factureButtonWasPressed: false,

            allowEditMode: false,
            editMode: false
        }
    });

    component.useEffect(() => {
        if (_props?.order?.facture?.date) {
            component.data.factureDate = _props.order.facture.date;
        } else {
            component.data.factureDate = null;
        }

        if (_props?.order?.facture?.invoice) {
            component.data.factureInvoice = _props.order.facture.invoice;
        } else {
            component.data.factureInvoice = null;
        }

        if (_props?.order?.facture?.comment) {
            component.data.factureComment = _props.order.facture.comment;
        } else {
            component.data.factureComment = '';
        }

        if (_props?.order?.facture?.id && !_props?.readOnly) {
            component.data.allowEditMode = true;
        } else if (!_props?.readOnly) {
            component.data.editMode = true;
        }
    }, [_props?.order])

    component.useEffect(() => {
        if (component.data.editMode) {
            if (typeof component.data.factureDate === 'string') {
                const date = DateTime.fromISO(component.data.factureDate);
                if (date.isValid) {
                    component.data.factureDate = date.toJSDate();
                }
            } else if (component.data.factureDate === null) {
                component.data.factureDate = DateTime.now().toJSDate();
            }
        } else {
            if (typeof component.data.factureDate === 'string') {
                const date = DateTime.fromISO(component.data.factureDate);
                if (date.isValid) {
                    component.data.factureDate = date.toFormat('yyyy-MM-dd');
                }
            } else if (component.data.factureDate instanceof Date) {
                const date = DateTime.fromJSDate(component.data.factureDate);
                component.data.factureDate = date.toFormat('yyyy-MM-dd');
            }
        }
    }, [
        component.data.editMode
    ]);

    component.useEffect(() => {
        if (component.data.factureButtonWasPressed) {
            handleValidation();
        }
    }, [
        component.data.factureDate,
        component.data.factureInvoice,
        component.data.factureComment
    ]);

    const handleValidation = () => {
        component.data.errorFactureDate = utils.validate.string.isEmpty(component.data.factureDate)
            ? 'Ingresa una fecha válida'
            : '';

        component.data.errorFactureInvoice = utils.validate.string.isEmpty(component.data.factureInvoice)
            ? 'Ingresa el número de factura'
            : '';

        component.data.errorFactureFilePDF = !filePDF?.current.contain.file()
            ? 'Selecciona el archivo PDF'
            : '';

        component.data.errorFactureFileXML = !fileXML?.current.contain.file()
            ? 'Selecciona el archivo XML'
            : '';

        if (
            component.data.errorFactureDate === ''
            && component.data.errorFactureInvoice === ''
            && component.data.errorFactureComment === ''
            && component.data.errorFactureFilePDF === ''
            && component.data.errorFactureFileXML === ''
        ) {
            return true;
        }

        return false;
    }

    const onSubmit = async () => {
        if (typeof _props?.onSubmit === 'function') {
            component.data.factureButtonWasPressed = true;
            if (handleValidation()) {
                const date = DateTime.fromJSDate(component.data.factureDate);
                const edited = Boolean(_props?.order?.facture?.id && component.data.editMode);

                await _props.onSubmit({
                    panel: {
                        id: _props?.panel?.id,
                    },
                    order: {
                        id: _props?.order?.id,
                    },
                    invoice: String(component.data.factureInvoice),
                    comment: component.data.factureComment,
                    date: date.toUTC(),
                    file: {
                        xml: {
                            upload: fileXML.current.upload
                        },
                        pdf: {
                            upload: filePDF.current.upload
                        }
                    },
                    edited: edited,
                    new: !edited,
                });

                if (edited) {
                    component.data.editMode = false;
                }
            }
        }
    }

    const InfoUserRegister = () => {
        const DateToLocal = () => {
            const date = DateTime.fromISO(_props?.order?.facture?.createdAt);
            if (date.isValid) {
                return date.toFormat('yyyy-MM-dd');
            }
            return null;
        }

        return <>
            <NeoColumn col={7 }>
                <p class="p-dialog__registered-date">
                    Factura registrada el <DateToLocal />
                    {_props?.order?.facture?.registeredByUser &&
                        ` por ${_props.order.facture.registeredByUser?.userDetail?.fullName}`
                    }
                </p>
            </NeoColumn>
        </>
    }

    const footerNeoDialog = () => {
        const dialogFooter = [
            _props?.order?.facture?.createdAt && <InfoUserRegister />
            ,
            <NeoButtonMain
                key={0}
                label="Cerrar"
                extra="hola"
                onClick={() => { _props.onHide(false) }}
            />
        ];

        if (!_props?.order?.facture?.id && component.data.editMode) {
            dialogFooter.push(
                <NeoButtonMain
                    key={1}
                    label="Registrar"
                    onClick={onSubmit}
                    disabled={_props.loading}
                    loading={_props.loading}
                />
            )
        } else if (component.data.allowEditMode && !component.data.editMode) {
            dialogFooter.push(
                <NeoButtonMain
                    key={2}
                    label="Modificar"
                    onClick={() => {
                        component.data.editMode = true;
                    }}
                    disabled={_props.loading}
                    loading={_props.loading}
                />
            )
        } else if (component.data.editMode) {
            dialogFooter.push(
                
                <NeoButtonMain
                    key={3}
                    label="Guardar"
                    onClick={onSubmit}
                    disabled={_props.loading}
                    loading={_props.loading}
                />
            )
        }

        return dialogFooter;
    }

    return (
        <NeoDialog
            visible={_props.visible}
            onHide={_props.onHide}
            header={_props.header}
            footer={footerNeoDialog}
            extra="modal-factura"

        >
            {/* Falta ajustar márgenes superiores y espaciado */}
            <NeoGridContainer extra="p-p-1 p-mt-1" >
                {(component.data.editMode && component.data.factureDate instanceof Date)
                    ? <NeoInputCalendar
                        label="Fecha de facturación"
                        value={component.data.factureDate}
                        error={component.data.errorFactureDate}
                        onChange={(event) => {
                            component.data.factureDate = event.value;
                        }}
                        col="6"
                    />
                    : <NeoInputText
                        label="Fecha de facturación"
                        value={component.data.factureDate}
                        error={component.data.errorFactureDate}
                        readOnly={true}
                        disabled={true}
                        col="6"
                    />
                }
                <NeoInputNumber
                    label="Número de folio"
                    useGrouping={false}
                    value={component.data.factureInvoice}
                    onChange={(event) => {
                        component.data.factureInvoice = event.value;
                    }}
                    error={component.data.errorFactureInvoice}
                    readOnly={!component.data.editMode}
                    disabled={!component.data.editMode}
                    col="6"
                />
            </NeoGridContainer>

            <NeoInputTextarea
                label="Comentario"
                rows={4}
                value={component.data.factureComment}
                onChange={(event) => {
                    component.data.factureComment = event.target.value;
                }}
                error={component.data.errorFactureComment}
                readOnly={!component.data.editMode}
                disabled={!component.data.editMode}
            />

            <NeoGridContainer>
                <NeoColumn extra="file-preview">
                    <span className="file-preview__title">Archivo PDF</span>
                    <AdminCustomerOrderFactureModalFileComponent
                        ref={filePDF}
                        accept=".pdf"
                        typeName="PDF"
                        icon={faFilePdf}
                        panel={_props?.panel}
                        order={_props?.order}
                        editMode={component.data.editMode}
                        labelError={component.data.errorFactureFilePDF}
                        onSelect={() => {
                            component.data.errorFactureFilePDF = '';
                        }}
                    />
                </NeoColumn>

                <NeoColumn extra="file-preview">
                    <span className="file-preview__title">Archivo XML</span>
                    <AdminCustomerOrderFactureModalFileComponent
                        ref={fileXML}
                        accept=".xml"
                        typeName="XML"
                        icon={faFileCode}
                        panel={_props?.panel}
                        order={_props?.order}
                        editMode={component.data.editMode}
                        labelError={component.data.errorFactureFileXML}
                        onSelect={() => {
                            component.data.errorFactureFileXML = '';
                        }}
                    />
                </NeoColumn>

                
            </NeoGridContainer>

        </NeoDialog>
    )
}

const AdminCustomerOrderFactureModalFileComponent = forwardRef((_props, _refInputFile) => {
    const component = useComponent({
        data: {
            file: null,
            actionsBeforeUpload: []
        }
    });

    const refInputFile = useRef();

    const getPathFolder = () =>
        `/NeoAdm/panels/${_props?.panel?.id}/factures/${_props?.order?.id}/`;

    const storage = utils.app.storage(getPathFolder());

    const fnFileFind = component.useAsync(storage.find, {
        name: 'fileFind'
    });

    const fnFileUpload = component.useAsync(storage.upload, {
        name: 'fileUpload'
    });

    component.useEffect(() => {
        const loadFileFromStorage = async () => {
            const [file] = await fnFileFind({
                extension: _props?.accept.split('.').pop()
            });
            component.data.file = file;
        }

        loadFileFromStorage().catch(console.error);
    }, [])

    useImperativeHandle(_refInputFile, () => ({
        upload: async () => {
            await Promise.all(component.data.actionsBeforeUpload.map(fn => fn()));
            component.data.actionsBeforeUpload = [];

            if (refInputFile?.current?.state.files.length > 0) {
                await uploadHandler(refInputFile?.current?.state.files)
                    .then((file) => {
                        component.data.file = file;
                    });
            }

            return true;
        },
        contain: {
            file: () => {
                if (refInputFile?.current?.state.files.length > 0) {
                    return true;
                } else if (component.data.file && component.data.actionsBeforeUpload.length === 0) {
                    return true;
                }

                return false;
            }
        }
    }));

    const uploadHandler = async (_files) => {
        const upload = new Promise((resolve) => {
            fnFileUpload(_files, {
                byFile: (result) => {
                    if (result.upload) {
                        resolve(result.file);
                    }
                },
            });
        });

        return await upload;
    }

    const verifyProps = () => {
        if (!_refInputFile) {
            console.error('Parametro "ref" no recibido');
            return false;
        } else if (!_props?.accept) {
            console.error('Parametro "accept" no recibido');
            return false;
        } else if (!_props?.typeName) {
            console.error('Parametro "typeName" no recibido');
            return false;
        } else if (!utils.object.isObject(_props?.panel)) {
            console.error('Parametro "panel" no es de tipo objeto');
            return false;
        } else if (!utils.object.isObject(_props?.order)) {
            console.error('Parametro "order" no es de tipo objeto');
            return false;
        } else if (!_props?.icon) {
            console.error('Parametro "icon" no recibido');
            return false;
        }

        return true;
    }

    return (verifyProps() && (<>
        {component.isLoading && (
            <NeoColumn>
                <FontAwesomeIcon
                    icon={faSpinner}
                    spin
                />
            </NeoColumn>
        )}

        {!component.isLoading && (
            component.data.file
                ? (
                    <AdminCustomerOrderFactureModalFileShowComponent
                        file={component.data.file}
                        icon={_props?.icon}
                        editMode={_props?.editMode}
                        onClick={component.data.file.download}
                        allowRemove={true}
                        onRemove={async (_file) => {
                            component.data.file = null;
                            component.data.actionsBeforeUpload.push(_file.del);
                        }}
                    />
                )
                : (
                    _props?.editMode && (
                        <AdminCustomerOrderFactureModalFileUploadComponent
                            ref={refInputFile}
                            accept={_props.accept}
                            icon={_props.icon}
                            uploadHandler={uploadHandler}
                            labelError={_props.labelError}
                            onSelect={_props.onSelect}
                        />
                    )
                )
        )}
    </>))
})

const AdminCustomerOrderFactureModalFileUploadComponent = forwardRef((_props, _refInputFile) => {
    const component = useComponent({
        data: {
            file: null
        }
    });

    const onSelect = (_event) => {
        const [file] = _event.files;

        if (`.${utils.file.getExtension(file?.name)}` !== _props.accept) {
            _refInputFile.current.clear();
            return;
        }

        if (utils.validate.fn(_props?.onSelect)) {
            _props.onSelect(file);
        }

        component.data.file = file;
    }

    const onClear = () => {
        component.data.file = null;
    }

    const renderClassNeoInputFile = () => {
        const classes = [];

        if (component.data?.file) {
            classes.push('p-d-none');
        }

        if (_props.labelError) {
            classes.push('error');
        }

        return classes.join(' ');
    }

    const verifyProps = () => {
        if (!_props?.accept) {
            console.error('Parametro "accept" no recibido');
            return false;
        } else if (!_props?.icon) {
            console.error('Parametro "icon" no recibido');
            return false;
        } else if (!utils.validate.fn(_props?.uploadHandler)) {
            console.error('Parametro "uploadHandler" no recibido');
            return false;
        }

        return true;
    }

    return (verifyProps() && (<>
        <NeoInputFile
            ref={_refInputFile}
            accept={_props.accept}
            extra={renderClassNeoInputFile()}
            maxFileSize={200000000}
            customUpload
            uploadHandler={_props.uploadHandler}
            multiple={false}
            emptyTemplate={<p>O arrastra aquí tu archivo.</p>}
            chooseLabel={`Seleccionar`}
            onSelect={onSelect}
            onClear={onClear}
            cancelOptions={{
                className: 'p-d-none'
            }}
            uploadOptions={{
                className: 'p-d-none'
            }}
        />

        {_props.labelError && (
            <NeoColumn>
                {_props.labelError}
            </NeoColumn>
        )}

        {component.data.file && (
            <AdminCustomerOrderFactureModalFileShowComponent
                icon={_props.icon}
                file={component.data.file}
                editMode={true}
                onRemove={_refInputFile.current.clear}
            />
        )}
    </>));
})

const AdminCustomerOrderFactureModalFileShowComponent = (_props) => {
    const verifyProps = () => {
        if (!_props?.file) {
            console.error('Parametro "file" no recibido');
            return false;
        } else if (!_props?.icon) {
            console.error('Parametro "icon" no recibido');
            return false;
        } else if (_props?.onClick) {
            if (!utils.validate.fn(_props?.onClick)) {
                console.error('Parametro "onClick" no es de tipo function');
                return false;
            }
        } else if (_props?.editMode) {
            if (!utils.validate.fn(_props?.onRemove)) {
                console.error('Parametro "onRemove" no es de tipo function');
                return false;
            }
        }

        return true;
    }

    return (verifyProps() && (<>
        <NeoColumn extra="file-preview__content">
            <div class="file-preview__icon-wrapper">
                <FontAwesomeIcon
                    size="4x"
                    icon={_props.icon}
                    onClick={_props.onClick}
                />
                {_props?.editMode && (
                    <NeoButtonMain
                        extra="delete-button"
                        // icon={pi-times-circle}
                        label={<i className="pi pi-times-circle"></i>}
                        onClick={() => {
                            _props.onRemove(_props.file);
                        }}
                    />
                )}
            </div>
            <span className="file-preview__file-name">{_props.file.name}</span>
        </NeoColumn>

    </>));
}

export default AdminCustomerOrderFactureModalFormComponent;