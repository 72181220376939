export const NEO_VALIDADOR = {
    ID: 1,
    NAME: 'NeoValidador'
};

export const NEO_WIFI = {
    ID: 2,
    NAME: 'NeoWifi',
};

export const NEO_CONECTA = {
    ID: 3,
    NAME: 'NeoConecta'
};

export const NEO_ADMIN = {
    NAME: 'NeoAdmin'
};