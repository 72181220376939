import * as firebase from "services/firebase.service";
import utils from "utils";

export const getToken = async () => {
    return await firebase.auth?.currentUser.getIdToken(true);
}

export const createToken = async (session) => {
    try {
        const authorization = await getToken();

        const result = await utils.http.request({
            url: `${utils.app.env('API_HOST_URL_BASE')}/api/v1/session/createToken`,
            method: 'post',
            headers: {
                'Authorization': authorization
            },
            data: {
                session: session
            }
        });

        return result?.data;
    } catch (error) {
        throw error;
    }
}

export const getTokenAuth = async (token, session) => {
    try {
        const result = await utils.http.request({
            url: `${utils.app.env('API_HOST_URL_BASE')}/api/v1/session/getToken`,
            method: 'post',
            data: {
                token,
                session
            }
        });

        return result?.data;
    } catch (error) {
        throw error;
    }
}

export const getInfo = async () => {
    try {
        const authorization = await getToken();

        const result = await utils.http.request({
            url: `${utils.app.env('API_HOST_URL_BASE')}/api/v1/session/info`,
            method: 'get',
            headers: {
                'Authorization': authorization
            }
        });

        return result?.data;
    } catch (error) {
        throw error;
    }
}