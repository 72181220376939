import { createContext } from "react";
import { useComponent } from "hooks";

const GeneralContext = createContext({});

export const GeneralContextProvider = (_props) => {
    const componente = useComponent({
        data: {}
    });

    return (
        <GeneralContext.Provider value={{
            data: componente.data,
        }}>
            {_props.children}
        </GeneralContext.Provider>
    )
}

export default GeneralContext;