import { useComponent, useTrigger, useUser } from "hooks";
import ROUTES from 'routers/Routes.router.json';

const CustomerBase = (_props) => {
    const user = useUser();
    const component = useComponent();
    const trigger = useTrigger();

    component.useEffect(() => {
        trigger.event('set.navBarItems', {
            data: [
                {
                    label: user.userDetail.email,
                    redirect: ROUTES.logout,
                    className: 'signout'
                }
            ]
        });

        return () => {
            trigger.event('set.navBarItems', {
                data: []
            });
        }
    }, [])

    return _props.children;
}

export default CustomerBase;