import { DateTime } from "luxon";
import { useRef } from "react";
import NeoMultiSelect from "design/design_components/neo/form/NeoMultiSelect.base";
import NeoTable from "design/design_components/neo/table/NeoTable.base";
import NeoTableColumn from "design/design_components/neo/table/NeoTableColumn.base";
import { useComponent } from "hooks";
import NeoInputText from "design/design_components/neo/form/NeoInputText.base";
import PeriodFilterDialog from "design/design_components/neo/filters/PeriodFilterDialog.base";

const AdminCustomersAccountFacturesTableComponent = (_props) => {
    const component = useComponent({
        data: {
            columnFilterDNOptions: [
                {
                    id: 1,
                    name: 'Sandra Cancino'
                },
                {
                    id: 2,
                    name: 'Andrea Ruiz'
                },
                {
                    id: 3,
                    name: 'Diego Gutiérrez'
                },
            ],
            columnFilterDNSelected: [],

            columnFilterAppsOptions: [
                {
                    id: 1,
                    name: 'NeoValidador'
                },
                {
                    id: 2,
                    name: 'NeoWifi'
                },
                {
                    id: 3,
                    name: 'NeoConecta'
                },
            ],
            columnFilterAppsSelected: [],

            columnFilterReviewerOptions: [
                {
                    id: 1,
                    name: 'Sandra Cancino'
                },
                {
                    id: 2,
                    name: 'Andrea Ruiz'
                },
                {
                    id: 3,
                    name: 'Diego Gutiérrez'
                },
            ],
            columnFilterReviewerSelected: [],

            columnFilterLastReviewDateShow: false,
            columnFilterLastReviewDateValue: null,
        }
    });

    const tableRef = useRef(null);

    const column = {
        ID: {
            body: (rowData) => {
                return (<>
                    <span className="p-column-title">ID</span>
                    {rowData.id}
                </>);
            },
        },

        DN: {
            body: (rowData) => {
                return (<>
                    <span className="p-column-title">DN</span>
                    {rowData.dn.userDetail.name}
                </>);
            },
            filter: <NeoMultiSelect
                value={component.data.columnFilterDNSelected}
                options={component.data.columnFilterDNOptions}
                itemTemplate={(option) => {
                    return (
                        <div className="p-multiselect-representative-option">
                            <span className="image-text">{option.name}</span>
                        </div>
                    );
                }}
                onChange={(event) => {
                    tableRef.current.filter(event.value, 'dn.id', 'in');
                    component.data.columnFilterDNSelected = event.value;
                }}
                optionLabel="name"
                optionValue="id"
                placeholder="Todos"
                className="p-column-filter"
            />
        },

        company: {
            body: (rowData) => {
                return (<>
                    <span className="p-column-title">Empresa</span>
                    {rowData.userDetail.company}
                </>);
            }
        },

        mainEmail: {
            body: (rowData) => {
                return (<>
                    <span className="p-column-title">Correo principal</span>
                    {rowData.userDetail.email}
                </>);
            }
        },

        apps: {
            body: (rowData) => {
                return (<>
                    <span className="p-column-title">Apps Activas</span>
                    {rowData.apps?.map((app) => app.name).join(', ')}
                </>);
            },
            filter: <NeoMultiSelect
                value={component.data.columnFilterAppsSelected}
                options={component.data.columnFilterAppsOptions}
                itemTemplate={(option) => {
                    return (
                        <div className="p-multiselect-representative-option">
                            <span className="image-text">{option.name}</span>
                        </div>
                    );
                }}
                onChange={(event) => {
                    tableRef.current.filter(event.value, 'apps', 'in');
                    component.data.columnFilterAppsSelected = event.value;
                }}
                optionLabel="name"
                optionValue="id"
                placeholder="Todas"
                className="p-column-filter"
            />
        },

        reviewer: {
            body: (rowData) => {
                return (<>
                    <span className="p-column-title">Revisor</span>
                    {(rowData?.userReviewed?.length > 0) && (
                        rowData.userReviewed[0].userReviewer.userDetail.name
                    )}
                </>);
            },
            filter: <NeoMultiSelect
                value={component.data.columnFilterReviewerSelected}
                options={component.data.columnFilterReviewerOptions}
                itemTemplate={(option) => {
                    return (
                        <div className="p-multiselect-representative-option">
                            <span className="image-text">{option.name}</span>
                        </div>
                    );
                }}
                onChange={(event) => {
                    tableRef.current.filter(event.value, 'reviewer.id', 'in');
                    component.data.columnFilterReviewerSelected = event.value;
                }}
                optionLabel="name"
                optionValue="id"
                placeholder="Todos"
                className="p-column-filter"
            />
        },

        lastReviewDate: {
            body: (_rowData) => {
                const getDate = () => {
                    if (_rowData?.userReviewed?.length > 0) {
                        const date = DateTime.fromISO(_rowData.userReviewed[0].date);
                        if (date.isValid) {
                            return date.toFormat('yyyy-MM-dd');
                        }
                    }
                }

                return (<>
                    <span className="p-column-title">Fecha de la última revisión</span>
                    {getDate()}
                </>);
            },
            filter: <NeoInputText
                label="Periodo"
                value={component.data.columnFilterLastReviewDateValue?.label}
                onClick={() => {
                    component.data.columnFilterLastReviewDateShow = true;
                }}
            />
        }
    };

    const onRowSelect = (row) => {
        if (typeof _props.onRowSelect === 'function') {
            _props.onRowSelect(row.data);
        }
    }

    return (<>
        <NeoTable
            selectionMode="single"
            value={_props.data}
            paginator
            rows={10}
            removableSort
            ref={tableRef}
            onRowSelect={onRowSelect}
            loading={_props.loading}
            emptyMessage="Sin datos"
            sortField="lastReviewDate"
            sortOrder={-1}
        >

            <NeoTableColumn
                field="id"
                header="Id cliente"
                sortable
                body={column.ID.body}
                filter
                filterMatchMode="contains"
            />

            {_props.showColumnDN && (
                <NeoTableColumn
                    field="dn.userDetail.name"
                    header="DN"
                    sortable
                    body={column.DN.body}
                    filter
                    filterElement={column.DN.filter}
                />
            )}

            <NeoTableColumn
                field="userDetail.company"
                header="Empresa"
                sortable
                body={column.company.body}
                filter
                filterMatchMode="contains"
            />

            <NeoTableColumn
                field="userDetail.email"
                header="Correo principal"
                sortable
                body={column.mainEmail.body}
                filter
                filterMatchMode="contains"
            />

            <NeoTableColumn
                header="Apps Activas"
                sortable
                body={column.apps.body}
                filter
                filterElement={column.apps.filter}
            />

            {_props.showColumnReviewer && (
                <NeoTableColumn
                    header="Revisor"
                    sortable
                    body={column.reviewer.body}
                    filter
                    filterElement={column.reviewer.filter}
                />
            )}

            <NeoTableColumn
                field="lastReviewDate"
                header="Última revisión"
                sortable
                body={column.lastReviewDate.body}
                filter
                filterElement={column.lastReviewDate.filter}
            />

        </NeoTable>

        <PeriodFilterDialog
            visible={component.data.columnFilterLastReviewDateShow}
            visibleSetter={(visible) => {
                component.data.columnFilterLastReviewDateShow = visible;
            }}
            value={component.data.columnFilterLastReviewDateValue}
            onChange={(event) => {
                component.data.columnFilterLastReviewDateValue = event;
            }}
        />
    </>)
}

export default AdminCustomersAccountFacturesTableComponent;