import { createContext, useState } from 'react';

import AppContextDefaultValues from 'contexts/app/app.context.default.values.json';

let initialState = { ...AppContextDefaultValues };

const AppContext = createContext({ data: initialState });

export const AppContextProvider = (_props) => {
    const [data, setData] = useState(initialState);

    return (
        <AppContext.Provider value={{
            data,
            setData
        }}>
            {_props.children}
        </AppContext.Provider>
    );
}

export default AppContext;