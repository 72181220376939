import NeoColumn from "design/design_components/neo/layout/NeoColumn.base"
import NeoInnerTitle from "design/design_components/neo/title/NeoInnerTitle.base"
import NeoInputText from "design/design_components/neo/form/NeoInputText.base"
import NeoInputPassword from "design/design_components/neo/form/NeoInputPassword.base"
import NeoButtonSection from "design/design_components/neo/layout/NeoButtonSection.base"
import NeoButtonMain from "design/design_components/neo/button/NeoButtonMain.base"
import NeoInputMask from "design/design_components/neo/form/NeoInputMask.base"
import NeoCheckbox from "design/design_components/neo/form/NeoCheckbox.base"
import { useComponent } from "hooks";
import utils from "utils"
import { REGEX } from "constants"

const RegisterFormComponent = (_props) => {
    const component = useComponent({
        data: {
            name: '',
            lastName: '',
            phone: '',
            company: '',
            email: '',
            password: '',
            verifyPassword: '',
            termsOfService: false,

            errorName: '',
            errorLastName: '',
            errorPhone: '',
            errorCompany: '',
            errorEmail: '',
            errorPassword: '',
            errorVerifyPassword: '',
            errorTerminsOfService: '',

            formIsInvalid: true,

            sendButtonWasPressed: false
        }
    });

    component.useEffect(() => {
        if (component.data.sendButtonWasPressed) {
            handleValidation();
        }
    }, [
        component.data.name,
        component.data.phone,
        component.data.company,
        component.data.email,
        component.data.password,
        component.data.verifyPassword
    ]);

    const handleValidation = () => {
        component.data.errorName = !utils.validate.string.isEmpty(component.data.name)
            ? ''
            : 'Debes introducir tu nombre';

        component.data.errorLastName = !utils.validate.string.isEmpty(component.data.lastName)
            ? ''
            : 'Debes introducir tu apellido';

        component.data.errorPhone = utils.validate.telephone(component.data.phone)
            ? ''
            : 'Debes introducir un teléfono válido';

        component.data.errorCompany = !utils.validate.string.isEmpty(component.data.company)
            ? ''
            : 'Debes introducir la empresa a la que perteneces';

        component.data.errorEmail = utils.validate.email(component.data.email)
            ? ''
            : 'El correo no tiene formato válido';

        if (component.data.errorEmail === '') {
            component.data.errorEmail = !utils.validate.emailFreeDomain(component.data.email)
                ? ''
                : 'Por favor, ingresa un correo electrónico empresarial';
        }

        component.data.errorPassword = REGEX.PASSWORD.test(component.data.password)
            ? ''
            : 'Debes introducir una contraseña válida';

        component.data.errorVerifyPassword = component.data.password === component.data.verifyPassword
            ? ''
            : 'La contraseña no es la misma';

        component.data.errorTerminsOfService = component.data.termsOfService
            ? ''
            : 'Es necesario aceptar los términos y condiciones para registrarte';

        if (
            component.data.errorName === ''
            && component.data.errorPhone === ''
            && component.data.errorCompany === ''
            && component.data.errorEmail === ''
            && component.data.errorPassword === ''
            && component.data.errorVerifyPassword === ''
            && component.data.errorTerminsOfService === ''
        ) {
            return true;
        }

        return false;
    }

    const handleSubmit = () => {
        if (typeof _props.onSubmit === 'function') {
            component.data.sendButtonWasPressed = true;
            if (handleValidation()) {
                _props.onSubmit({
                    name: component.data.name,
                    lastName: component.data.lastName,
                    phone: component.data.phone,
                    company: component.data.company,
                    email: component.data.email,
                    password: component.data.password
                });
            }
        }
    }

    const onKeyDown = (event) => {
        if (event.key === "Enter") {
            handleSubmit();
        }
    }

    return (
        <NeoColumn col={12} extra="px-0">
            <NeoInnerTitle
                extra="primero"
            >
                Crea una cuenta
            </NeoInnerTitle>

            <NeoInputText
                label="Nombre"
                value={component.data.name}
                onChange={(event) => {
                    component.data.name = event.target.value;
                }}
                error={component.data.errorName}
                onKeyDown={onKeyDown}
                disabled={_props.loading}
                autoComplete="off"
            />

            <NeoInputText
                label="Apellido"
                value={component.data.lastName}
                onChange={(event) => {
                    component.data.lastName = event.target.value;
                }}
                error={component.data.errorLastName}
                onKeyDown={onKeyDown}
                disabled={_props.loading}
                autoComplete="off"
            />

            <NeoInputMask
                mask="(99) 9999-9999"
                unmask
                value={component.data.phone}
                onChange={(event) => {
                    component.data.phone = event.target.value;
                }}
                label="Teléfono"
                error={component.data.errorPhone}
                onKeyDown={onKeyDown}
                disabled={_props.loading}
                autoComplete="off"
            />

            <NeoInputText
                label="Empresa"
                value={component.data.company}
                onChange={(event) => {
                    component.data.company = event.target.value;
                }}
                error={component.data.errorCompany}
                onKeyDown={onKeyDown}
                disabled={_props.loading}
                autoComplete="off"
            />

            <NeoInputText
                label="Correo electrónico"
                value={component.data.email}
                onChange={(event) => {
                    component.data.email = event.target.value;
                }}
                error={component.data.errorEmail}
                onKeyDown={onKeyDown}
                disabled={_props.loading}
                autoComplete="off"
            />

            <NeoInputPassword
                label="Contraseña"
                value={component.data.password}
                onChange={(event) => {
                    component.data.password = event.target.value;
                }}
                onPaste={(e) => e.preventDefault()}
                onCopy={(e) => e.preventDefault()}
                error={component.data.errorPassword}
                onKeyDown={onKeyDown}
                disabled={_props.loading}
                autoComplete="off"
                strongRegex={REGEX.PASSWORD}
            />

            <NeoInputPassword
                label="Repite tu contraseña"
                value={component.data.verifyPassword}
                onChange={(event) => {
                    component.data.verifyPassword = event.target.value;
                }}
                onPaste={(e) => e.preventDefault()}
                onCopy={(e) => e.preventDefault()}
                error={component.data.errorVerifyPassword}
                onKeyDown={onKeyDown}
                disabled={_props.loading}
                autoComplete="off"
                noFeedback
            />

            <NeoCheckbox
                extra="p-mt-0"
                id="aceptaCondiciones"
                value={component.data.termsOfService}
                onChange={(event) => {
                    component.data.termsOfService = event.checked;
                }}
                label="Acepto términos y condiciones de uso"
                error={component.data.errorTerminsOfService}
                disabled={_props.loading}
                autoComplete="off"
            />

            <NeoButtonSection>
                <NeoButtonMain
                    label="Crear cuenta"
                    onClick={handleSubmit}
                    disabled={_props.loading}
                    loading={_props.loading}
                />
            </NeoButtonSection>
        </NeoColumn>
    )
}

export default RegisterFormComponent;