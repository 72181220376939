import { storage } from "services/firebase.service";

/**
 * Establece metodos para el uso de storage
 * @param  {storageRef} ref - Referencia de storage
 * @return Instancia de storage
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default (_ref) => {
    if (typeof _ref === 'string') {
        _ref = storage.ref(storage.instance, _ref);
    }

    /**
     * Lista los archivos
     * @return Array con la lista de archivos
     */
    const list = async () => {
        if (_ref === undefined) {
            throw handleError('Ref no recibida');
        }

        return storage.listAll(_ref).then((_res) => {
            if (Array.isArray(_res.items)) {
                return _res.items.map((_file) => {
                    return actionsFile(_file);
                });
            }
            return [];
        }).catch((_error) => {
            throw handleError('Error al listar los archivos');
        });
    }

    /**
     * Elimina todos los archivos
     * @return true/false en caso de error
     */
    const del = async () => {
        if (_ref === undefined) {
            throw handleError('Ref no recibida');
        }

        try {
            const files = await list();

            const promises = [];
            files.forEach((_file) => {
                promises.push(_file.del());
            });

            await Promise.all(promises);
            return true;
        } catch (error) {
            throw handleError('Error al eliminar los archivos');
        }
    }

    /**
     * Permite subir uno o varias archivos
     * @param  {files} array - Array con los archivos a subir
     * @param  {params} object - Objeto con las opciones de la petición
     * @param  {params.byFile} function - Función que se ejecutara cada cambio en el archivo
     * @param  {params.byTask} function - Función que se ejecutara por ejecución de la función subir
     * @param  {params.onFinish} function - Función que se ejecutara al terminar todas las peticiones
     */
    const upload = async (_files, _params = {}) => {
        if (_ref === undefined) {
            throw handleError('Ref no recibida');
        } else if (_files === undefined) {
            throw handleError('Archivo(s) no recibidos');
        } else if (!Array.isArray(_files)) {
            _files = [_files];
        }

        if (_params.maxFiles !== undefined) {
            const detailsRef = await getDetails();
            const totalFiles = detailsRef.totalFiles + _files.length;
            if (totalFiles > _params.maxFiles) {
                throw handleError(`Solo se permite subir ${_params.maxFiles} archivo(s)`);
            }
        }

        if (_params.sizeMaxFile !== undefined) {
            _files.forEach((_file) => {
                if (_file.size > _params.sizeMaxFile) {
                    const Mb = Math.round((_params.sizeMaxFile / 1000000) * 10) / 10;
                    throw handleError(`
                        El tamaño del archivo "${_file.name}" 
                        supera el tamaño maximo de ${Mb} Mb permitido por archivo.
                    `);
                }
            });
        }

        if (Array.isArray(_params.extensionsAllowed)) {
            _files.forEach((_file) => {
                const extension = getExtensionFile(_file.name);
                if (!_params.extensionsAllowed.includes(extension)) {
                    throw handleError(`
                        El archivo "${_file.name}" 
                        es un tipo de archivo no permitido.
                    `);
                }
            })
        }

        const statusFiles = [];

        const promises = _files.map((_file, _key) => {
            let fileRef = storage.ref(_ref, _file.name);
            const task = storage.uploadBytesResumable(fileRef, _file);

            task.on('state_changed', (_snapshot) => {
                const progress = (_snapshot.bytesTransferred / _snapshot.totalBytes) * 100;

                if (typeof _params?.byFile === 'function') {
                    _params.byFile({
                        progress: progress,
                        status: _snapshot.state
                    });
                }

                if (typeof _params?.byTask === 'function') {
                    statusFiles[_key] = {
                        progress: progress
                    };

                    const progressTotal = statusFiles.reduce(
                        (_progressTotal, _file) => (_progressTotal + _file.progress)
                        , 0) / _files.length;

                    _params.byTask({
                        progress: Math.round(progressTotal)
                    });
                }
            }, (error) => {
                if (typeof _params?.byFile === 'function') {
                    _params.byFile({
                        progreso: 100,
                        status: error.code,
                        subido: false
                    });
                }
            }, () => {
                if (typeof _params?.byFile === 'function') {
                    _params.byFile({
                        progress: 100,
                        status: 'done',
                        upload: true,
                        file: actionsFile(task.snapshot.ref)
                    });
                }

            });

            return task;
        });

        Promise.allSettled(promises).then((_result) => {
            let success = 0;
            let failed = 0;

            _result.forEach((_result) => {
                if (_result.status === 'fulfilled') {
                    success++;
                } else if (_result.status === 'rejected') {
                    failed++;
                }
            });

            if (typeof _params?.onFinish === 'function') {
                _params.onFinish({
                    success: success,
                    failed: failed
                });
            }
        });
    }

    /**
     * Obtiene los detalles del archivo
     * @return promise: al cumplirse retorna un objeto con los datos generales del archivo
     */
    const getDetails = async () => {
        try {
            const filesInStorage = await list();
            const totalFiles = filesInStorage.length;

            return {
                totalFiles,
                files: filesInStorage
            }
        } catch (error) {
            throw handleError('Error al obtener los detalles del archivo');
        }
    }

    const find = async (_find) => {
        if (_ref === undefined) {
            throw handleError('Ref no recibida');
        }

        try {
            const files = await list();
            return files.filter((_file) => {
                let match = false;

                if (_find?.extension !== undefined) {
                    match = _find?.extension === _file.extension;
                }

                return match;
            })
        } catch (error) {
            return null;
        }
    }

    return {
        list,
        del,
        upload,
        getDetails,
        find
    }
}

/**
 * Establece metodos para el uso por archivo
 * @param  {file} _file - Archivo a añadir dichos metodos
 * @return Instancia de archivo
 */
const actionsFile = (_file) => {
    if (_file === undefined) {
        throw handleError('Archivo no instanciado');
    }

    /**
     * Obtiene el tipo de extensión
     * @return string | undefined: Extensión del archivo
     */
    const getExtension = () => {
        return (/[.]/.exec(_file.name)) ? /[^.]+$/.exec(_file.name)[0] : undefined;
    }

    /**
     * Obtiene la url de descarga
     * @return promise: al cumplirse retorna un string con la url de descarga
     */
    const getUrlDownload = async () => {
        return storage.getDownloadURL(_file).then((_urlDownload) => {
            return _urlDownload;
        }).catch((error) => {
            switch (error.code) {
                case 'storage/object-not-found':
                    throw handleError('El archivo no existe');

                case 'storage/unauthorized':
                    throw handleError('No cuentas con permisos para acceder al archivo');

                case 'storage/canceled':
                    throw handleError('Operación cancelada');

                case 'storage/unknown':
                default:
                    throw handleError('Error inesperado');
            }
        });
    }

    /**
     * Obtiene los metadatos del archivo
     * @return promise: al cumplirse retorna un objeto con los metadatos
     */
    const getMetadata = async () => {
        return _file.getMetadata().then((_metadata) => {
            return _metadata;
        }).catch((error) => {
            throw handleError('Error al obtener los metadatos del archivo');
        });
    }

    /**
     * Obtiene los detalles del archivo
     * @return promise: al cumplirse retorna un objeto con los datos generales del archivo
     */
    const getDetails = async () => {
        try {
            const metadata = await getMetadata();

            return {
                name: _file.name,
                contentType: metadata.contentType,
                size: metadata.size,
                extension: getExtension()
            }
        } catch (error) {
            throw handleError('Error al obtener los detalles del archivo');
        }
    }

    /**
     * Permite descargar el archivo
     * @return promise: al cumplirse descarga el archivo
     */
    const download = async (_url, _name) => {
        try {
            if (typeof _url !== 'string') {
                _url = await getUrlDownload();
            }

            if (_name === undefined) {
                _name = _file.name;
            }

            const xhr = new XMLHttpRequest();
            xhr.open("GET", _url, true);
            xhr.responseType = "blob";
            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        const blob = new Blob([xhr.response], {
                            type: "application/octetstream"
                        });

                        const isIE = false || !!document.documentMode;
                        if (isIE) {
                            window.navigator.msSaveBlob(blob, _name);
                        } else {
                            const url = window.URL || window.webkitURL;
                            const link = url.createObjectURL(blob);
                            const a = document.createElement("a");
                            a.setAttribute('download', _name);
                            a.setAttribute('href', link);
                            document.body.appendChild(a);
                            a.click();
                            document.body.removeChild(a);
                        }
                    } else {
                        console.error("Ajax error for " + _url + ": " + xhr.status);
                    }
                }
            };
            xhr.send();

        } catch (error) {
            throw handleError('Error al descargar el archivo');
        }
    }

    /**
     * Permite abrir un archivo en el navegador
     * @return promise: al cumplirse retorna abre una pestaña del navegador para descargar el archivo
     */
    const open = async (_url, _newWindow = true) => {
        try {
            if (_url === undefined) {
                _url = await getUrlDownload();
            }

            const a = document.createElement('a');
            a.setAttribute('href', _url);
            if (_newWindow) {
                a.setAttribute('target', '_blank');
            }
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        } catch (error) {
            throw handleError('Error al abrir el archivo');
        }
    }

    /**
     * Permite eliminar el archivo
     * @return promise: al cumplirse retorna true si el archivo fue eliminado
     */
    const del = async () => {
        try {
            await storage.deleteObject(_file);
            return true;
        } catch (error) {
            throw handleError('Error al eliminar el archivo');
        }
    }

    return {
        getUrlDownload,
        getDetails,
        download,
        open,
        del,
        name: _file.name,
        extension: getExtension()
    }
}

/**
 * Establece el mensaje de error
 * @param  {error} String - Texto con el error
 * @return Instancia de handleError
 */
const handleError = (_error) => {
    return {
        message: _error
    };
}

const getExtensionFile = (_nameFile) => {
    const extension = _nameFile.slice((_nameFile.lastIndexOf(".")) + 1);
    return extension === _nameFile ? "" : extension;
}