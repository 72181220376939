import { useRef } from "react";
import NeoTable from "design/design_components/neo/table/NeoTable.base";
import NeoTableColumn from "design/design_components/neo/table/NeoTableColumn.base";
import NeoButtonMain from "design/design_components/neo/button/NeoButtonMain.base";

const AdminCustomersBillingAccountsTableComponent = (_props) => {
    const tableRef = useRef(null);

    const column = {
        name: {
            body: (_rowData) => {
                return (<>
                    <span className="p-column-title">Nombre</span>
                    {_rowData.name}
                </>);
            }
        },

        rfc: {
            body: (_rowData) => {
                return (<>
                    <span className="p-column-title">RFC</span>
                    {_rowData.rfc}
                </>);
            }
        },

        actions: {
            body: (_rowData) => {
                return (<>
                    <span className="p-column-title">RFC</span>
                    <NeoButtonMain
                        label="Ver detalles"
                        onClick={() => {
                            onRowSelect({
                                data: _rowData
                            });
                        }}
                    />
                </>);
            }
        },

    };

    const onRowSelect = (row) => {
        if (typeof _props.onRowSelect === 'function') {
            _props.onRowSelect(row.data);
        }
    }

    return (<>
        <NeoTable
            selectionMode="single"
            value={_props.data}
            paginator
            rows={10}
            removableSort
            ref={tableRef}
            onRowSelect={onRowSelect}
            loading={_props.loading}
            emptyMessage="Sin datos"
            sortField="name"
            sortOrder={-1}
        >

            <NeoTableColumn
                field="name"
                header="Nombre"
                sortable
                body={column.name.body}
                filter
                filterMatchMode="contains"
            />

            <NeoTableColumn
                field="rfc"
                header="RFC"
                sortable
                body={column.rfc.body}
                filter
                filterMatchMode="contains"
            />

            <NeoTableColumn
                field="rfc"
                header="Acciones"
                body={column.actions.body}
            />

        </NeoTable>
    </>)
}

export default AdminCustomersBillingAccountsTableComponent;