import CustomRouter, { Switch } from 'routers/Custom.router';
import ROUTES from 'routers/Routes.router.json';

import CustomerBase from 'bases/Customer.base';

import CustomerDashboardView from 'views/customer/dashboard/Customer.dashboard.view';

const CustomerRouter = () => {
    return (
        <CustomerBase>
            <Switch>
                <CustomRouter
                    path={ROUTES.customer.index}
                    component={CustomerDashboardView}
                />
            </Switch>
        </CustomerBase>
    )
}

export default CustomerRouter;