import NeoGridContainer from "design/design_components/neo/layout/NeoGridContainer.base";
import NeoColumn from "design/design_components/neo/layout/NeoColumn.base";
import LogoValidador from "design/assets/img/mnv/logo.svg";
import LogoWifi from "design/assets/img/wfi/Logo-NeoWifi.svg";
import LogoConecta from "design/assets/img/cta/logo.svg";

const CustomerDashboardView = () => {
    return (
        <NeoGridContainer>
            <NeoColumn col={4}>
                <a href={process.env.REACT_APP_HOST_URL_NEOWIFI_BASE}>
                    <img style={{ height: '115px' }} src={LogoWifi} alt="NeoWifi" />
                </a>
            </NeoColumn>
            <NeoColumn col={4}>
                <a href={process.env.REACT_APP_HOST_URL_NEOVALIDADOR_BASE}>
                    <img src={LogoValidador} alt="NeoValidador" />
                </a>
            </NeoColumn>
            <NeoColumn col={4}>
                <a href={process.env.REACT_APP_HOST_URL_NEOCONECTA_BASE}>
                    <img src={LogoConecta} alt="NeoConecta" />
                </a>
            </NeoColumn>
        </NeoGridContainer>
    )
}

export default CustomerDashboardView;