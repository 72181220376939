import NeoHeader from "design/design_components/neo/layout/NeoHeader.base";
import NeoNavBar from "design/design_components/neo/menu/NeoNavBar.base";
import NeoMainContainer from "design/design_components/neo/layout/NeoMainContainer.base";
import Logo from 'design/assets/img/adm/logo-bw.png'
import { useComponent, useTrigger } from "hooks";

const UserBase = (_props) => {
    const trigger = useTrigger();
    const component = useComponent({
        data: {
            navBarItems: []
        }
    });

    trigger.listener((navBarItems) => {
        component.data.navBarItems = navBarItems;
    }, 'set.navBarItems')

    return (
        <NeoHeader>
            <NeoNavBar items={component.data.navBarItems}
                extra='navbar-principal blue-nav p-jc-between'
                logo={Logo}
            />
            <NeoMainContainer>
                {_props.children}
            </NeoMainContainer>
        </NeoHeader>
    )
}

export default UserBase;