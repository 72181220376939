import CustomRouter, { Switch, useLocation } from 'routers/Custom.router';
import ROUTES from 'routers/Routes.router.json';

import AdminBase from 'bases/Admin.base';

import AdminDashboardView from 'views/admin/dashboard/Admin.dashboard.view';
import AdminCustomersView from 'views/admin/customers/Admin.customers.view';
import AdminCustomerConsumesGeneralsView from 'views/admin/consumesGenerals/Admin.customer.consumesGenerals.view';
import AdminCustomerPanelsView from 'views/admin/panels/Admin.customer.panels.view';
import AdminAppsNeoDashboardView from 'views/admin/apps/neo/dashboard/Admin.apps.neo.dashboard.view';

const AdminRouter = () => {
    const location = useLocation();

    return (
        <AdminBase>
            <Switch>
                <CustomRouter
                    path={ROUTES.admin.index}
                    component={AdminDashboardView}
                />
                <CustomRouter
                    path={ROUTES.admin.customers.list}
                    component={AdminCustomersView}
                />
                <CustomRouter
                    path={ROUTES.admin.customers.consumes.generals}
                    component={AdminCustomerConsumesGeneralsView}
                    redirect={() => {
                        return Boolean(location?.state?.customer);
                    }}
                    redirectValue={false}
                    toPath={ROUTES.admin.customers.list}
                />
                <CustomRouter
                    path={ROUTES.admin.customers.panels}
                    component={AdminCustomerPanelsView}
                    redirect={() => {
                        return Boolean(
                            location?.state?.billingAccount
                            &&
                            location?.state?.customer
                        );
                    }}
                    redirectValue={false}
                    toPath={ROUTES.admin.customers.consumes.generals}
                />
                <CustomRouter
                    path={ROUTES.admin.apps.neo.dashboard}
                    component={AdminAppsNeoDashboardView}
                />
            </Switch>
        </AdminBase>
    )
}

export default AdminRouter;