import { useHistory } from "react-router-dom";
import NeoGridContainer from "design/design_components/neo/layout/NeoGridContainer.base";
import NeoTitleMain from "design/design_components/neo/title/NeoTitleMain.base";
import Icon from 'design/assets/img/mnv/icons/icon-user.svg';
import AdminCustomersTableComponent from "./components/Admin.customers.table.component";
import * as customersService from "services/customers.service";
import { useComponent, useUser } from "hooks";
import ROUTES from 'routers/Routes.router.json';

const AdminCustomersView = (_props) => {
    const user = useUser();
    const history = useHistory();
    const component = useComponent({
        data: {
            customers: []
        }
    });

    const fnCustomersList = component.useAsync(customersService.list);

    component.useEffect(() => {
        const getCustomers = async () => {
            const { customers } = await fnCustomersList();
            component.data.customers = customers;
        }

        getCustomers().catch(console.error);
    }, [])

    const customerTableOnRowSelect = (row) => {
        history.push(ROUTES.admin.customers.consumes.generals, {
            customer: row
        });
    }

    return (<>
        <NeoGridContainer>
            <NeoTitleMain icon={Icon} title="Clientes" />
        </NeoGridContainer>

        <AdminCustomersTableComponent
            data={component.data.customers}
            showColumnDN={user.isAdmin() || user.isDNManager() || user.isAccountant() || user.isSupport()}
            showColumnReviewer={user.isAdmin() || user.isDNManager() || user.isAccountant() || user.isSupport()}
            loading={component.isLoading}
            onRowSelect={customerTableOnRowSelect}
        />
    </>
    )
}

export default AdminCustomersView;