import NeoDialog from "design/design_components/neo/overlay/NeoDialog.base";
import NeoButtonMain from "design/design_components/neo/button/NeoButtonMain.base";
import NeoColumn from "design/design_components/neo/layout/NeoColumn.base";
import NeoInputTextarea from "design/design_components/neo/form/NeoInputTextarea.base";
import { useComponent } from "hooks";

const AdminCustomerReviewModalFormComponent = (_props) => {
    const component = useComponent({
        data: {
            comment: '',
            errorComment: '',

            reviewButtonWasPressed: false
        }
    });

    component.useEffect(() => {
        if (component.data.reviewButtonWasPressed) {
            handleValidation();
        }
    }, [
        component.data.comment
    ]);

    const handleValidation = () => {
        if (component.data.errorComment === ''
        ) {
            return true;
        }

        return false;
    }

    const onSubmit = () => {
        if (typeof _props?.onSubmit === 'function') {
            component.data.reviewButtonWasPressed = true;
            if (handleValidation()) {
                _props.onSubmit({
                    comment: component.data.comment
                });
            }
        }
    }

    const footerNeoDialog = () => {
        return (<>
            <NeoButtonMain
                label="Cancelar"
                onClick={() => { _props.onHide(false) }}
            />
            <NeoButtonMain
                label="Registrar"
                onClick={onSubmit}
                disabled={_props.loading}
                loading={_props.loading}
            />
        </>)
    }

    return (
        <NeoDialog
            visible={_props.visible}
            onHide={_props.onHide}
            header={'Registrar revisión'}
            footer={footerNeoDialog}
        >
            <NeoColumn>
                El registro de revisión de cliente ayuda a identificar si
                ya atendiste a este cliente durante este mes. No tiene
                funcionalidad adicional de facturación ni cobros.
            </NeoColumn>

            <NeoInputTextarea
                label="Información adicional"
                rows={4}
                value={component.data.comment}
                onChange={(event) => {
                    component.data.comment = event.target.value;
                }}
                error={component.data.errorComment}
                readOnly={_props?.readOnly}
                disabled={_props?.readOnly}
            />

        </NeoDialog>
    )
}

export default AdminCustomerReviewModalFormComponent;