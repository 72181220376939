/* App */
import * as app from './app';

/* Validations */
import * as validate from './validate';

/* Generators */
import * as generate from './generate';

/* Objects */
import * as object from './object';

/* Arrays */
import * as array from './array';

/* Http */
import * as http from './http';

/* Convert */
import * as convert from './convert';

/* File */
import * as file from './file';

const utils = {
    app,
    validate,
    generate,
    object,
    array,
    http,
    convert,
    file
}

export default utils;