import { DateTime } from "luxon";
import NeoGridContainer from "design/design_components/neo/layout/NeoGridContainer.base";
import NeoTitleSecond from "design/design_components/neo/title/NeoTitleSecond.base";
import Icon from 'design/assets/img/mnv/icons/icon-user.svg';
import NeoTabView from "design/design_components/neo/panel/NeoTabView.base";
import NeoTabPanel from "design/design_components/neo/panel/NeoTabPanel.base";
import NeoButtonSection from "design/design_components/neo/layout/NeoButtonSection.base";
import NeoCalendar from "design/design_components/neo/form/NeoCalendar.base";
import * as panelsService from "services/panels.service";
import * as ordersService from "services/orders.service";
import AdminCustomersOrdesNeoValidadorTableComponent from "../customers/orders/components/Admin.customer.orders.neovalidador.table.component";
import AdminCustomersOrdesNeoWifiTableComponent from "../customers/orders/components/Admin.customer.orders.neowifi.table.component";
import AdminCustomersOrdesNeoConectaTableComponent from "../customers/orders/components/Admin.customer.orders.neoconecta.table.component";
import { useComponent, useUser } from "hooks";
import { useLocation } from "react-router-dom";
import { APP } from "../../../constants";

const AdminCustomerPanelsView = () => {
    const location = useLocation();
    const component = useComponent({
        data: {
            billingAccount: location.state.billingAccount,
            customer: location.state.customer,
            activeIndexTabPanel: 0,
            panels: [],
            dateStart: new Date()
        }
    });

    const fnPanelsList = component.useAsync(panelsService.list, {
        name: 'panelsList'
    });

    component.useEffect(() => {
        const getPanels = async () => {
            const { panels } = await fnPanelsList({
                billingAccount: {
                    id: component.data.billingAccount.id
                },
                customer: {
                    id: component.data.customer.id
                }
            });
            component.data.panels = panels;
        }

        getPanels().catch(console.error);
    }, [])

    return (<>
        <NeoGridContainer>
            <NeoTitleSecond
                icon={Icon}
                title={
                    `Consumos 
                    ${component.data.customer.userDetail.name}
                    ${component.data.customer.userDetail.lastName}
                    `
                }
            />
            <NeoButtonSection
                align="right"
            >
                <NeoCalendar
                    md="4"
                    value={component.data.dateStart}
                    readOnlyInput
                    label="Año - Mes"
                    onChange={(event) => {
                        component.data.dateStart = event.value;
                    }}
                    dateFormat="yy-mm"
                    showButtonBar
                    view="month"
                    maxDate={new Date()}
                />
            </NeoButtonSection>
        </NeoGridContainer>

        <NeoTabView
            activeIndex={component.data.activeIndexTabPanel}
            onTabChange={(event) => {
                component.data.activeIndexTabPanel = event.index;
            }}
        >
            {component.data.panels.map((panel, index) => {
                return (
                    <NeoTabPanel
                        key={index}
                        header={panel.name}
                    >
                        <RenderAdminCustomersOrdesTableComponent
                            panel={panel}
                            dateStart={component.data.dateStart}
                        />
                    </NeoTabPanel>
                )
            })}

        </NeoTabView>
    </>)
};

const RenderAdminCustomersOrdesTableComponent = (_props) => {
    const user = useUser();
    const component = useComponent({
        orders: []
    });

    const fnOrdersList = component.useAsync(ordersService.list, {
        name: 'ordersList'
    });
    const fnPaymentOrder = component.useAsync(ordersService.payment, {
        name: 'paymentOrder'
    });
    const fnFactureOrder = component.useAsync(ordersService.facture, {
        name: 'factureOrder'
    });
    const fnFactureOrderEdit = component.useAsync(ordersService.factureEdit, {
        name: 'factureOrderEdit'
    });

    component.useEffect(() => {
        getOrders();
    }, [_props.dateStart])

    const getOrders = async () => {
        try {
            const date = DateTime.fromJSDate(_props.dateStart);

            const { orders } = await fnOrdersList({
                panel: {
                    id: _props.panel.id
                },
                date: {
                    start: date.startOf('month').toJSDate(),
                    end: date.endOf('month').toJSDate()
                }
            });
            component.data.orders = orders;
        } catch (error) {
            console.log(error);
        }
    }

    const onPayment = async (_payment) => {
        try {
            await fnPaymentOrder(_payment);
            getOrders();
            return true;
        } catch (error) {
            console.log(error)
        }
    }

    const onFacture = async (_facture) => {
        try {
            const { file, ...facture } = _facture;

            const fnFacture = facture.edited
                ? fnFactureOrderEdit
                : fnFactureOrder;

            delete facture.edited;
            delete facture.new;

            await Promise.all([
                fnFacture(facture),
                file.pdf.upload(),
                file.xml.upload()
            ]);

            getOrders();

            return true;
        } catch (error) {
            console.log(error)
        }
    }

    return (<>
        {_props.panel?.appId === APP.NEO_VALIDADOR.ID && (
            <AdminCustomersOrdesNeoValidadorTableComponent
                panel={_props.panel}
                data={component.data.orders}
                loading={component.calls['ordersList']?.state === 'sending'}
                permissions={{
                    payment: user.isAdmin() || user.isDN() || user.isDNSales() || user.isDNManager() || user.isAccountant() || user.isSupport(),
                    facture: user.isAdmin() || user.isAccountant()
                }}
                onPayment={onPayment}
                onPaymentLoading={component.calls['paymentOrder']?.state === 'sending'}
                onFacture={onFacture}
                onFactureLoading={
                    component.calls['factureOrder']?.state === 'sending'
                    ||
                    component.calls['factureOrderEdit']?.state === 'sending'
                }
            />
        )}

        {_props.panel?.appId === APP.NEO_WIFI.ID && (
            <AdminCustomersOrdesNeoWifiTableComponent
                panel={_props.panel}
                data={component.data.orders}
                loading={component.calls['ordersList']?.state === 'sending'}
                permissions={{
                    payment: user.isAdmin() || user.isDN() || user.isDNSales() || user.isDNManager() || user.isAccountant() || user.isSupport(),
                    facture: user.isAdmin() || user.isAccountant()
                }}
                onPayment={onPayment}
                onPaymentLoading={component.calls['paymentOrder']?.state === 'sending'}
                onFacture={onFacture}
                onFactureLoading={
                    component.calls['factureOrder']?.state === 'sending'
                    ||
                    component.calls['factureOrderEdit']?.state === 'sending'
                }
            />
        )}

        {_props.panel?.appId === APP.NEO_CONECTA.ID && (
            <AdminCustomersOrdesNeoConectaTableComponent
                panel={_props.panel}
                data={component.data.orders}
                loading={component.calls['ordersList']?.state === 'sending'}
                permissions={{
                    payment: user.isAdmin() || user.isDN() || user.isDNSales() || user.isDNManager() || user.isAccountant() || user.isSupport(),
                    facture: user.isAdmin() || user.isAccountant()
                }}
                onPayment={onPayment}
                onPaymentLoading={component.calls['paymentOrder']?.state === 'sending'}
                onFacture={onFacture}
                onFactureLoading={
                    component.calls['factureOrder']?.state === 'sending'
                    ||
                    component.calls['factureOrderEdit']?.state === 'sending'
                }
            />
        )}

    </>)
}

export default AdminCustomerPanelsView;