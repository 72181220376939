import { DateTime } from "luxon";
import NeoDialog from "design/design_components/neo/overlay/NeoDialog.base";
import NeoButtonMain from "design/design_components/neo/button/NeoButtonMain.base";
import NeoInputText from "design/design_components/neo/form/NeoInputText.base";
import NeoInputTextarea from "design/design_components/neo/form/NeoInputTextarea.base";
import NeoColumn from "design/design_components/neo/layout/NeoColumn.base";
import { useComponent } from "hooks";
import utils from "utils";

const AdminCustomerOrderPaymentModalFormComponent = (_props) => {
    const component = useComponent({
        data: {
            paymentDate: '',
            paymentReference: '',
            paymentComment: '',

            errorPaymentDate: '',
            errorPaymentReference: '',
            errorPaymentComment: '',

            paymentButtonWasPressed: false,

            allowEditMode: false,
            editMode: false
        }
    });

    component.useEffect(() => {
        if (_props?.order?.payment?.date) {
            component.data.paymentDate = _props.order.payment.date;
        } else {
            component.data.paymentDate = null;
        }

        if (_props?.order?.payment?.reference) {
            component.data.paymentReference = _props.order.payment.reference;
        } else {
            component.data.paymentReference = null;
        }

        if (_props?.order?.payment?.comment) {
            component.data.paymentComment = _props.order.payment.comment;
        } else {
            component.data.paymentComment = '';
        }

        if (_props?.order?.payment?.id && !_props?.readOnly) {
            component.data.allowEditMode = true;
        } else if (!_props?.readOnly) {
            component.data.editMode = true;
        }
    }, [_props?.order])

    component.useEffect(() => {
        if (component.data.editMode) {
            if (typeof component.data.paymentDate === 'string') {
                const date = DateTime.fromISO(component.data.paymentDate);
                if (date.isValid) {
                    component.data.paymentDate = date.toJSDate();
                }
            } else if (component.data.paymentDate === null) {
                component.data.paymentDate = DateTime.now().toJSDate();
            }
        } else {
            if (typeof component.data.paymentDate === 'string') {
                const date = DateTime.fromISO(component.data.paymentDate);
                if (date.isValid) {
                    component.data.paymentDate = date.toFormat('yyyy-MM-dd');
                }
            } else if (component.data.paymentDate instanceof Date) {
                const date = DateTime.fromJSDate(component.data.paymentDate);
                component.data.paymentDate = date.toFormat('yyyy-MM-dd');
            }
        }
    }, [
        component.data.editMode
    ]);

    component.useEffect(() => {
        if (component.data.paymentButtonWasPressed) {
            handleValidation();
        }
    }, [
        component.data.paymentDate,
        component.data.paymentReference,
        component.data.paymentComment
    ]);

    const handleValidation = () => {
        component.data.errorPaymentDate = utils.validate.string.isEmpty(component.data.paymentDate)
            ? 'Ingresa una contraseña'
            : '';

        component.data.errorPaymentReference = utils.validate.string.isEmpty(component.data.paymentReference)
            ? 'Ingresa el número de referencia'
            : '';

        component.data.errorPaymentComment = utils.validate.string.isEmpty(component.data.paymentComment)
            ? 'Ingresa un comentario'
            : '';

        if (
            component.data.errorPaymentDate === ''
            && component.data.errorPaymentReference === ''
            && component.data.errorPaymentComment === ''
        ) {
            return true;
        }

        return false;
    }

    const onSubmit = async () => {
        if (typeof _props?.onSubmit === 'function') {
            component.data.paymentButtonWasPressed = true;
            if (handleValidation()) {
                const date = DateTime.fromJSDate(component.data.paymentDate);

                await _props.onSubmit({
                    panel: {
                        id: _props?.panel?.id,
                    },
                    order: {
                        id: _props?.order?.id,
                    },
                    reference: String(component.data.paymentReference),
                    comment: component.data.paymentComment,
                    date: date.toUTC()
                });
            }
        }
    }

    const InfoUserRegister = () => {
        const DateToLocal = () => {
            const date = DateTime.fromISO(_props?.order?.payment?.createdAt);
            if (date.isValid) {
                return date.toFormat('yyyy-MM-dd');
            }
            return null;
        }

        return <>
            <NeoColumn col={12}>
                Pago registrado el <DateToLocal />
                {_props?.order?.payment?.registeredByUser &&
                    ` por ${_props.order.payment.registeredByUser?.userDetail?.fullName}`
                }
            </NeoColumn>
        </>
    }

    const footerNeoDialog = () => {
        if (_props?.readOnly) {
            return <NeoButtonMain label="Cerrar" onClick={() => { _props.onHide(false) }} />
        } else {
            return (<>
                <NeoButtonMain
                    label="Cancelar"
                    onClick={() => { _props.onHide(false) }}
                />
                <NeoButtonMain
                    label="Registrar"
                    onClick={onSubmit}
                    disabled={_props.loading}
                    loading={_props.loading}
                />
            </>)
        }
    }

    return (
        <NeoDialog
            visible={_props.visible}
            onHide={_props.onHide}
            header={_props.header}
            footer={footerNeoDialog}
        >

            <NeoInputText
                label="Fecha de pago"
                value={(() => {
                    if (component.data.paymentDate instanceof Date) {
                        return DateTime.fromJSDate(component.data.paymentDate).toFormat('yyyy-MM-dd');
                    }
                    return component.data.paymentDate;
                })()}
                error={component.data.errorPaymentDate}
                readOnly={true}
                disabled={true}
            />

            <NeoInputText
                label="Referencia"
                value={component.data.paymentReference}
                onChange={(event) => {
                    component.data.paymentReference = event.target.value;
                }}
                autoComplete={false}
                error={component.data.errorPaymentReference}
                readOnly={_props?.readOnly}
                disabled={_props?.readOnly}
            />

            <NeoInputTextarea
                label="Información adicional"
                rows={4}
                value={component.data.paymentComment}
                onChange={(event) => {
                    component.data.paymentComment = event.target.value;
                }}
                error={component.data.errorPaymentComment}
                readOnly={_props?.readOnly}
                disabled={_props?.readOnly}
            />

            {_props?.order?.payment?.createdAt && <InfoUserRegister />}
        </NeoDialog>
    )
}

export default AdminCustomerOrderPaymentModalFormComponent;