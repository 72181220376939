import CustomRouter, { Switch } from 'routers/Custom.router';
import { GeneralContextProvider } from 'contexts/General.context';
import ROUTES from 'routers/Routes.router.json';

import GeneralBase from 'bases/General.base';

import LoginView from 'views/login/Login.view';
import RegisterView from "views/register/Register.view";
import PasswordRecoveryView from "views/password/Password.recovery.view";
import PasswordResetView from "views/password/Password.reset.view";

const GeneralRouter = () => {
    return (
        <GeneralContextProvider>
            <GeneralBase>
                <Switch>
                    {/* Login */}
                    <CustomRouter
                        path={ROUTES.login}
                        component={LoginView}
                    />

                    {/* Register */}
                    <CustomRouter
                        path={ROUTES.register}
                        component={RegisterView}
                    />

                    {/* Password Recovery */}
                    <CustomRouter
                        path={ROUTES.password.recovery}
                        component={PasswordRecoveryView}
                    />

                    {/* Password Reset */}
                    <CustomRouter
                        path={ROUTES.password.reset}
                        component={PasswordResetView}
                    />
                </Switch>
            </GeneralBase>
        </GeneralContextProvider>
    )
}

export default GeneralRouter;