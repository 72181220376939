import { useRef } from "react";
import { DateTime } from "luxon";
import NeoMultiSelect from "design/design_components/neo/form/NeoMultiSelect.base";
import NeoTable from "design/design_components/neo/table/NeoTable.base";
import NeoTableColumn from "design/design_components/neo/table/NeoTableColumn.base";
import { useComponent } from "hooks";
import NeoInputText from "design/design_components/neo/form/NeoInputText.base";
import PeriodFilterDialog from "design/design_components/neo/filters/PeriodFilterDialog.base";
import NeoColumn from "design/design_components/neo/layout/NeoColumn.base";
import NeoTooltip from "design/design_components/neo/overlay/NeoTooltip.base";
import NeoButtonMain from "design/design_components/neo/button/NeoButtonMain.base";
import AdminCustomerOrderPaymentModalFormComponent from "./Admin.customer.order.payment.modal.form.component";
import AdminCustomerOrderFactureModalFormComponent from "./Admin.customer.order.facture.modal.form.component";
import utils from "utils";

const AdminCustomersOrdesNeoValidadorTableComponent = (_props) => {
    const component = useComponent({
        data: {
            columnFilterCreationDateShow: false,
            columnFilterCreationDateValue: null,

            columnFilterProductOptions: [
                {
                    id: 1,
                    name: 'Validaciones de correo'
                },
                {
                    id: 2,
                    name: 'Validaciones de teléfono'
                }
            ],
            columnFilterProductSelected: [],

            columnFilterStatusOptions: [
                {
                    id: 1,
                    name: 'Pendiente de pago'
                },
                {
                    id: 2,
                    name: 'Pendiente de factura'
                },
                {
                    id: 3,
                    name: 'Sin pendientes'
                },
            ],
            columnFilterStatusSelected: [],

            columnFilterPaymentOptions: [
                {
                    value: true,
                    name: 'Pagados'
                },
                {
                    value: false,
                    name: 'Por pagar'
                }
            ],
            columnFilterPaymentSelected: [],

            columnFilterFactureOptions: [
                {
                    value: true,
                    name: 'Facturados'
                },
                {
                    value: false,
                    name: 'Por facturar'
                }
            ],
            columnFilterFactureSelected: [],

            modalPaymentShow: false,
            modalPaymentOrder: null,
            modalPaymentOrderReadOnly: true,

            modalFactureShow: false,
            modalFactureOrder: null,
            modalFactureOrderReadOnly: true,

            factureProcessing: []
        }
    });

    const tableRef = useRef(null);

    const column = {
        creation: {
            date: {
                body: (_rowData) => {
                    return (<>
                        <span className="p-column-title">Fecha de creación</span>
                        {_rowData.creation.date}
                    </>);
                },
                filter: <NeoInputText
                    value={component.data.columnFilterCreationDateValue?.label}
                    onClick={() => {
                        component.data.columnFilterCreationDateShow = true;
                    }}
                />
            }
        },

        ID: {
            body: (_rowData) => {
                return (<>
                    <span className="p-column-title">ID</span>
                    {_rowData.name_type_order} - {_rowData.id}
                    {_rowData?.information?.additional && <>
                        <NeoColumn>
                            <NeoTooltip
                                target={`.information-additional-icon-${_rowData.id}`}
                                mouseTrack={true}
                            >
                                {_rowData.information.additional}
                            </NeoTooltip>
                            <i className={`pi pi-info-circle information-additional-icon-${_rowData.id}`}></i>
                        </NeoColumn>
                    </>}
                </>);
            },
        },

        product: {
            body: (_rowData) => {
                return (<>
                    <span className="p-column-title">Cantidad</span>
                    {_rowData.products.map((_product, _index) => {
                        return (
                            <NeoColumn key={`${_rowData.id}-${_product.id}`} col="12">
                                {utils.convert.toCurrency(_product.quantity)}
                                {` `}
                                {_product.id === 1 &&
                                    <>
                                        <NeoTooltip
                                            target={`.product-icon-${_rowData.id}-${_product.id}`}
                                            mouseTrack={true}
                                        >
                                            {_product.fullName}
                                        </NeoTooltip>
                                        <i className={`pi pi-envelope product-icon-${_rowData.id}-${_product.id}`}></i>

                                    </>
                                }
                                {_product.id === 2 &&
                                    <>
                                        <NeoTooltip
                                            target={`.product-icon-${_rowData.id}-${_product.id}`}
                                            mouseTrack={true}
                                        >
                                            {_product.fullName}
                                        </NeoTooltip>
                                        <i className={`pi pi-mobile product-icon-${_rowData.id}-${_product.id}`}></i>

                                    </>
                                }
                            </NeoColumn>
                        )
                    })}
                </>);
            },
            filter: <NeoMultiSelect
                value={component.data.columnFilterProductSelected}
                options={component.data.columnFilterProductOptions}
                itemTemplate={(option) => {
                    return (
                        <div className="p-multiselect-representative-option">
                            <span className="image-text">{option.name}</span>
                        </div>
                    );
                }}
                onChange={(_event) => {
                    tableRef.current.filter(_event.value, 'product.id', 'in');
                    component.data.columnFilterProductSelected = _event.value;
                }}
                optionLabel="name"
                optionValue="id"
                placeholder="Todos"
                className="p-column-filter"
            />
        },

        quantity: {
            body: (_rowData) => {
                return (<>
                    <span className="p-column-title">Cantidad</span>
                    {utils.convert.toCurrency(_rowData.quantity)}
                </>);
            }
        },

        information: {
            additional: {
                body: (_rowData) => {
                    return (<>
                        <span className="p-column-title">Información adicional</span>
                        {_rowData.information.additional}
                    </>);
                }
            }
        },

        amount: {
            body: (_rowData) => {
                return (<>
                    <span className="p-column-title">Total</span>
                    ${utils.convert.toCurrency(_rowData.amount)}
                </>);
            }
        },

        status: {
            body: (_rowData) => {
                const getStatus = (_status) => {
                    if (Array.isArray(_status)) {
                        if (_status.length === 0) {
                            return 'Sin pendientes';
                        }
                        return `Pendiente de ` + _status.map((_status) => _status.name).join(' y ');
                    }
                    return '';
                }
                return (<>
                    <span className="p-column-title">Estatus</span>
                    {getStatus(_rowData.status)}
                </>);
            },
            filter: <NeoMultiSelect
                value={component.data.columnFilterStatusSelected}
                options={component.data.columnFilterStatusOptions}
                itemTemplate={(_option) => {
                    return (
                        <div className="p-multiselect-representative-option">
                            <span className="image-text">{_option.name}</span>
                        </div>
                    );
                }}
                onChange={(_event) => {
                    tableRef.current.filter(_event.value, 'status', 'in');
                    component.data.columnFilterStatusSelected = _event.value;
                }}
                optionLabel="name"
                optionValue="id"
                placeholder="Todos"
                className="p-column-filter"
            />
        },

        payment: {
            date: {
                body: (_rowData) => {
                    return (<>
                        <span className="p-column-title">Fecha de pago</span>
                        {_rowData.payment?.id
                            ? DateTime.fromISO(_rowData.payment.date).toFormat('yyyy-MM-dd')
                            : utils.validate.permissions(_props.permissions, 'payment.register')
                                ? < NeoButtonMain
                                    label="Registrar pago"
                                    onClick={() => {
                                        component.data.modalPaymentOrderReadOnly = false;
                                        component.data.modalPaymentOrder = _rowData;
                                        component.data.modalPaymentShow = true;
                                    }}
                                    disabled={component.data.paymentProcessing?.id === _rowData?.id}
                                    loading={component.data.paymentProcessing?.id === _rowData?.id}
                                />
                                : '-'
                        }
                    </>);
                },
                filter: <NeoMultiSelect
                    value={component.data.columnFilterPaymentSelected}
                    options={component.data.columnFilterPaymentOptions}
                    itemTemplate={(_option) => {
                        return (
                            <div className="p-multiselect-representative-option">
                                <span className="image-text">{_option.name}</span>
                            </div>
                        );
                    }}
                    onChange={(_event) => {
                        tableRef.current.filter(_event.value, 'payment.date', 'custom');
                        component.data.columnFilterPaymentSelected = _event.value;
                    }}
                    optionLabel="name"
                    optionValue="value"
                    placeholder="Todos"
                    className="p-column-filter"
                />,
                filterFunction: (value, filter) => {
                    return filter.includes(Boolean(value));
                }
            }
        },

        facture: {
            date: {
                body: (_rowData) => {
                    return (<>
                        <span className="p-column-title">Fecha de facturación</span>
                        {_rowData.facture?.id
                            ? DateTime.fromISO(_rowData.facture.date).toFormat('yyyy-MM-dd')
                            : utils.validate.permissions(_props.permissions, 'facture.register')
                                ? <NeoButtonMain
                                    label="Registrar facturación"
                                    onClick={() => {
                                        component.data.modalFactureOrderReadOnly = false;
                                        component.data.modalFactureOrder = _rowData;
                                        component.data.modalFactureShow = true;
                                    }}
                                    disabled={component.data.factureProcessing?.id === _rowData?.id}
                                    loading={component.data.factureProcessing?.id === _rowData?.id}
                                />
                                : '-'
                        }
                    </>);
                },
                filter: <NeoMultiSelect
                    value={component.data.columnFilterFactureSelected}
                    options={component.data.columnFilterFactureOptions}
                    itemTemplate={(_option) => {
                        return (
                            <div className="p-multiselect-representative-option">
                                <span className="image-text">{_option.name}</span>
                            </div>
                        );
                    }}
                    onChange={(_event) => {
                        tableRef.current.filter(_event.value, 'facture.date', 'custom');
                        component.data.columnFilterFactureSelected = _event.value;
                    }}
                    optionLabel="name"
                    optionValue="value"
                    placeholder="Todos"
                    className="p-column-filter"
                />,
                filterFunction: (value, filter) => {
                    return filter.includes(Boolean(value));
                }
            }
        },
    };

    const onRowSelect = (_event) => {
        const columnClicked = _event?.originalEvent?.target?.children[0]?.innerText;
        const data = _event.data;

        if (columnClicked === 'Fecha de pago') {
            if (data?.payment?.date) {
                component.data.modalPaymentOrderReadOnly = true;
                component.data.modalPaymentOrder = data;
                component.data.modalPaymentShow = true;
            }
        }
        else if (columnClicked === 'Fecha de facturación') {
            if (data?.facture?.date) {
                component.data.modalFactureOrderReadOnly = !utils.validate.permissions(_props.permissions, 'facture.edit');
                component.data.modalFactureOrder = data;
                component.data.modalFactureShow = true;
            }
        }
    }

    const onPayment = async (_order) => {
        if (utils.validate.isFNPromise(_props?.onPayment)) {
            component.data.paymentProcessing = _order;
            const result = await _props.onPayment(_order);
            if (result) {
                component.data.modalPaymentShow = false;
                component.data.paymentProcessing = null;
                return true;
            }
        }
    }

    const onFacture = async (_order) => {
        if (utils.validate.isFNPromise(_props?.onFacture)) {
            component.data.factureProcessing = _order;
            const result = await _props.onFacture(_order);
            if (result) {
                component.data.modalFactureShow = false;
                component.data.factureProcessing = null;
                return true;
            }
        }
    }

    return (<>
        <NeoTable
            selectionMode="single"
            value={_props.data}
            paginator
            rows={10}
            removableSort
            ref={tableRef}
            onRowSelect={onRowSelect}
            loading={_props.loading}
            emptyMessage="Sin consumos"
            sortField="creation.date"
            sortOrder={-1}
        >

            <NeoTableColumn
                field="creation.date"
                header="Fecha de creación"
                sortable
                body={column.creation.date.body}
                filter
                filterElement={column.creation.date.filter}
            />

            <NeoTableColumn
                field="id"
                header="ID de orden"
                sortable
                body={column.ID.body}
                filter
                filterMatchMode="contains"
            />

            <NeoTableColumn
                field="product.name"
                header="Producto"
                sortable
                body={column.product.body}
                filter
                filterMatchMode="contains"
            />

            <NeoTableColumn
                field="information.additional"
                header="Información adicional"
                sortable
                body={column.information.additional.body}
                filter
                filterMatchMode="contains"
            />

            <NeoTableColumn
                field="amount"
                header="Total"
                sortable
                body={column.amount.body}
                filter
                filterMatchMode="contains"
            />

            <NeoTableColumn
                field="payment.date"
                header="Fecha de pago"
                sortable
                body={column.payment.date.body}
                filter
                filterElement={column.payment.date.filter}
                filterFunction={column.payment.date.filterFunction}
            />

            <NeoTableColumn
                field="facture.date"
                header="Fecha de facturación"
                sortable
                body={column.facture.date.body}
                filter
                filterElement={column.facture.date.filter}
                filterFunction={column.facture.date.filterFunction}
            />

        </NeoTable>

        {/* Filter: Creation date */}
        <PeriodFilterDialog
            visible={component.data.columnFilterCreationDateShow}
            visibleSetter={(visible) => {
                component.data.columnFilterCreationDateShow = visible;
            }}
            value={component.data.columnFilterCreationDateValue}
            onChange={(event) => {
                component.data.columnFilterCreationDateValue = event;
            }}
        />
        {/* END Filter: Creation date */}

        {component.data.modalPaymentShow && (
            <AdminCustomerOrderPaymentModalFormComponent
                visible={component.data.modalPaymentShow}
                onHide={(visible) => {
                    component.data.modalPaymentShow = visible;
                }}
                header={() => {
                    return `Detalle de pago - 
                        ${component.data.modalPaymentOrder?.name_type_order}
                        -
                        ${component.data.modalPaymentOrder?.id}`;
                }}
                readOnly={component.data.modalPaymentOrderReadOnly}
                onSubmit={onPayment}
                loading={_props?.onPaymentLoading}
                panel={_props.panel}
                order={component.data.modalPaymentOrder}
            />
        )}

        {component.data.modalFactureShow && (
            <AdminCustomerOrderFactureModalFormComponent
                visible={component.data.modalFactureShow}
                onHide={(visible) => {
                    component.data.modalFactureShow = visible;
                }}
                header={() => {
                    return `Detalles de facturación - 
                        ${component.data.modalFactureOrder?.name_type_order}
                        -
                        ${component.data.modalFactureOrder?.id}`;
                }}
                readOnly={component.data.modalFactureOrderReadOnly}
                onSubmit={onFacture}
                loading={_props?.onFactureLoading}
                panel={_props.panel}
                order={component.data.modalFactureOrder}
            />
        )}

    </>)
}

export default AdminCustomersOrdesNeoValidadorTableComponent;