import { DateTime } from "luxon";
import NeoButtonMain from "design/design_components/neo/button/NeoButtonMain.base";
import NeoButtonSection from "design/design_components/neo/layout/NeoButtonSection.base";
import NeoGridContainer from "design/design_components/neo/layout/NeoGridContainer.base";
import NeoTitleMain from "design/design_components/neo/title/NeoTitleMain.base";
import Icon from 'design/assets/img/mnv/icons/icon-user.svg';
import NeoTabView from "design/design_components/neo/panel/NeoTabView.base";
import NeoTabPanel from "design/design_components/neo/panel/NeoTabPanel.base";
import NeoCard from "design/design_components/neo/panel/NeoCard.base";
import NeoColumn from "design/design_components/neo/layout/NeoColumn.base";
import NeoDataSecond from "design/design_components/neo/data/NeoDataSecond.base";
import * as billingAccountsService from "services/billingAccounts.service";
import * as reviewsService from "services/reviews.service";
import AdminCustomersBillingAccountsTableComponent from "./components/Admin.customers.billing.accounts.table.component";
import AdminCustomerReviewModalFormComponent from "../customers/orders/components/Admin.customer.review.modal.form.component";
import AdminCustomersReviewsTableComponent from "./components/Admin.customers.reviews.table.component";
import { useComponent } from "hooks";
import { useHistory, useLocation } from "react-router-dom";
import ROUTES from 'routers/Routes.router.json';

const AdminCustomerConsumesGeneralsView = () => {
    const location = useLocation();
    const history = useHistory();
    const component = useComponent({
        data: {
            customer: location.state.customer,
            modalReviewRegisterShow: false,
            modalReviewInfoShow: false,
            activeIndexTabPanel: 0,
            billingAccounts: [],
            reviews: [],
            lastReview: null
        }
    });

    const fnBillingAccountsList = component.useAsync(billingAccountsService.list, {
        name: 'billingAccounts'
    });
    const fnReviewsList = component.useAsync(reviewsService.list, {
        name: 'reviewsList'
    });
    const fnReviewsRegister = component.useAsync(reviewsService.register, {
        name: 'reviewsRegister'
    });

    component.useEffect(() => {
        if (component.data.activeIndexTabPanel === 1
            && component.data.billingAccounts.length === 0
        ) {
            getBillingAccounts().catch(console.error);
        }
        else if (component.data.activeIndexTabPanel === 0
            && component.data.reviews.length === 0
        ) {
            getReviews().catch(console.error);
        }
    }, [component.data.activeIndexTabPanel])

    const getReviews = async () => {
        const { reviews } = await fnReviewsList({
            customer: {
                id: component.data.customer.id
            }
        });
        component.data.reviews = reviews;

        if (Array.isArray(reviews)) {
            component.data.lastReview = reviews[reviews.length - 1];
        }
    }

    const getBillingAccounts = async () => {
        const { billingAccounts } = await fnBillingAccountsList({
            customer: {
                id: component.data.customer.id
            }
        });
        component.data.billingAccounts = billingAccounts;
    }

    const onReviewRegister = async (_review) => {
        try {
            const review = await fnReviewsRegister({
                customer: {
                    id: component.data.customer.id
                },
                comment: _review.comment
            });

            component.data.lastReview = review;
            component.data.modalReviewRegisterShow = false;
            getReviews();
        } catch (error) {
            console.log(error)
        }
    }

    const billingAccountsTableOnRowSelect = (_row) => {
        history.push(ROUTES.admin.customers.panels, {
            customer: component.data.customer,
            billingAccount: _row
        });
    }

    return (<>
        <NeoGridContainer>
            <NeoTitleMain
                icon={Icon}
                title={
                    `Consumos 
                    ${component.data.customer.userDetail.name}
                    ${component.data.customer.userDetail.lastName}
                    `
                }
            />
            <NeoButtonSection
                align="right"
            >
                <NeoButtonMain
                    label="Registrar revisión de cliente"
                    onClick={() => {
                        component.data.modalReviewRegisterShow = true;
                    }}
                />
            </NeoButtonSection>
        </NeoGridContainer>

        <NeoCard>
            <NeoColumn md={3}>
                <NeoDataSecond
                    fact={component.data.lastReview?.userReviewer.userDetail.name}
                    label="Revisor más reciente" />
            </NeoColumn>
            <NeoColumn md={3}>
                <NeoDataSecond
                    fact={(() => {
                        if (component.data.lastReview) {
                            const date = DateTime.fromISO(component.data.lastReview.date);
                            return date.toFormat('yyyy-MM-dd');
                        }
                    })()}
                    label="Fecha de última revisión" />
            </NeoColumn>
        </NeoCard>

        {component.data.modalReviewRegisterShow && (
            <AdminCustomerReviewModalFormComponent
                visible={component.data.modalReviewRegisterShow}
                onHide={(visible) => {
                    component.data.modalReviewRegisterShow = visible;
                }}
                onSubmit={onReviewRegister}
                loading={component.calls['reviewsRegister']?.state === 'sending'}
            />
        )}

        <NeoTabView
            activeIndex={component.data.activeIndexTabPanel}
            onTabChange={(event) => {
                component.data.activeIndexTabPanel = event.index;
            }}
        >
            <NeoTabPanel
                header="Historial de revisiones"
            >
                <AdminCustomersReviewsTableComponent
                    data={component.data.reviews}
                />
            </NeoTabPanel>
            <NeoTabPanel
                header="Cuentas de facturación"
            >
                <AdminCustomersBillingAccountsTableComponent
                    data={component.data.billingAccounts}
                    onRowSelect={billingAccountsTableOnRowSelect}
                />
            </NeoTabPanel>
        </NeoTabView>
    </>)
};

export default AdminCustomerConsumesGeneralsView;