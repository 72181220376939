import CustomRouter, { BrowserRouter, Switch } from "routers/Custom.router";
import ROUTES from 'routers/Routes.router.json';
import { useUser } from 'hooks';

/* Routes */
import UserRouter from 'routers/User.router';
import GeneralRouter from 'routers/General.router';

/* Views */
import LogoutView from 'views/logout/Logout.view';

const MainRouter = () => {
    const user = useUser();

    return (
        <BrowserRouter>
            <Switch>

                {/* Usuario logueado */}
                <CustomRouter
                    exact={false}
                    path={[
                        ROUTES.admin.index,
                        ROUTES.customer.index
                    ]}
                    redirect={user.isSignIn}
                    redirectValue={false}
                    component={UserRouter}
                />

                {/* Logout */}
                <CustomRouter
                    path={ROUTES.logout}
                    component={LogoutView}
                />

                {/* General */}
                <CustomRouter
                    path={[
                        ROUTES.login,
                        ROUTES.register,
                        ROUTES.password.recovery,
                        ROUTES.password.reset
                    ]}
                    component={GeneralRouter}
                />

                {/* Defecto */}
                <CustomRouter
                    path="/"
                    redirect
                    redirectToPath={user.getIndexPath}
                />

            </Switch>
        </BrowserRouter>
    )
}

export default MainRouter;