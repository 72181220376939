import { useContext } from "react";
import GeneralContext from 'contexts/General.context';

const useGeneral = () => {
    const general = useContext(GeneralContext);

    return {
        data: general.data
    }
}

export default useGeneral;