import NeoColumn from "design/design_components/neo/layout/NeoColumn.base"
import NeoInnerTitle from "design/design_components/neo/title/NeoInnerTitle.base"
import NeoInputPassword from "design/design_components/neo/form/NeoInputPassword.base"
import NeoButtonSection from "design/design_components/neo/layout/NeoButtonSection.base"
import NeoButtonMain from "design/design_components/neo/button/NeoButtonMain.base"
import { useComponent } from "hooks";
import { REGEX } from "constants"

const PasswordResetFormComponent = (_props) => {
    const component = useComponent({
        data: {
            password: '',

            errorPassword: '',

            formIsInvalid: true,

            sendButtonWasPressed: false
        }
    });

    component.useEffect(() => {
        if (component.data.sendButtonWasPressed) {
            handleValidation();
        }
    }, [
        component.data.password
    ]);

    const handleValidation = () => {
        component.data.errorPassword = REGEX.PASSWORD.test(component.data.password)
            ? ''
            : 'Debes introducir una contraseña válida';

        if (
            component.data.errorPassword === ''
        ) {
            return true;
        }

        return false;
    }

    const handleSubmit = () => {
        if (typeof _props.onSubmit === 'function') {
            component.data.sendButtonWasPressed = true;
            if (handleValidation()) {
                _props.onSubmit({
                    password: component.data.password
                });
            }
        }
    }

    const onKeyDown = (event) => {
        if (event.key === "Enter") {
            handleSubmit();
        }
    }

    return (
        <NeoColumn col={12} extra="px-0">
            <NeoInnerTitle
                extra="primero"
            >
                Reestablece tu contraseña
            </NeoInnerTitle>

            <NeoInputPassword
                label="Nueva contraseña"
                value={component.data.password}
                onChange={(event) => {
                    component.data.password = event.target.value;
                }}
                onPaste={(e) => e.preventDefault()}
                onCopy={(e) => e.preventDefault()}
                error={component.data.errorPassword}
                onKeyDown={onKeyDown}
                disabled={_props.loading}
                autoComplete="off"
                strongRegex={REGEX.PASSWORD}
            />

            <NeoButtonSection>
                <NeoButtonMain
                    label="Enviar"
                    onClick={handleSubmit}
                    disabled={_props.loading}
                    loading={_props.loading}
                />
            </NeoButtonSection>
        </NeoColumn>
    )
}

export default PasswordResetFormComponent;