import { useHistory } from "react-router-dom";
import ROUTES from "routers/Routes.router.json";
import utils from "utils";
import { useComponent, useTrigger, useUser } from "hooks";

const LogoutView = (_props) => {
    const user = useUser();
    const component = useComponent({
        data: {
            continueUrl: null
        }
    });
    const trigger = useTrigger();
    const history = useHistory();

    component.useEffect(() => {
        if (user.signOut()) {
            trigger.clear();

            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);

            const paramContinue = urlParams.get('continue');
            if (paramContinue) {
                if (utils.validate.URL(paramContinue)) {
                    component.data.continueUrl = paramContinue;
                }
            }

            window.history.replaceState(null, null, window.location.pathname);
        }
    }, [])

    component.useEffect(() => {
        const redirect = async () => {
            if (!user.isSignIn()) {
                if (component.data.continueUrl) {
                    const continueUrl = new URL(component.data.continueUrl);
                    window.location.replace(continueUrl);
                } else {
                    history.push(ROUTES.index);
                }
            }
        }

        redirect().catch(console.error);
    }, [user.data])

    return (
        <></>
    )
}

export default LogoutView;