import { useContext } from "react";
import {
    signInWithEmailAndPassword,
    signOut,
    sendPasswordResetEmail,
    confirmPasswordReset,
    verifyPasswordResetCode
} from "firebase/auth";
import UserContext from "contexts/User.context";
import * as usersService from "../services/users.service";
import { ROLE_USER } from "constants";
import ROUTES from 'routers/Routes.router.json';

const useUserHook = () => {
    const {
        data,
        auth,
        userAuth,
        user,
        loadDataUser
    } = useContext(UserContext);

    const signInAuth = async (_user) => {
        try {
            const user = await signInWithEmailAndPassword(
                auth,
                _user.email,
                _user.password
            );

            return user;
        } catch (error) {
            throw error;
        }
    }

    const signOutAuth = () => {
        try {
            signOut(auth);
            return true;
        } catch (error) {
            throw error;
        }
    }

    const register = async (_data) => {
        try {
            return await usersService.register(_data);
        } catch (error) {
            throw error;
        }
    }

    const recoveryPassword = async (_email) => {
        try {
            return await sendPasswordResetEmail(auth, _email);
        } catch (error) {
            throw error;
        }
    }

    const resetPassword = async (_hash, _newPassword) => {
        try {
            return await confirmPasswordReset(auth, _hash, _newPassword);
        } catch (error) {
            throw error;
        }
    }

    const verifyHashResetPassword = async (_hash, _newPassword) => {
        try {
            return await verifyPasswordResetCode(auth, _hash);
        } catch (error) {
            throw error;
        }
    }

    const isSignIn = () => {
        return Boolean(userAuth !== null && user);
    }

    const isAdmin = () => {
        return is(ROLE_USER.ADMIN.ID);
    }

    const isDN = () => {
        return is(ROLE_USER.DN.ID);
    }

    const isDNSales = () => {
        return is(ROLE_USER.DN_SALES.ID);
    }

    const isDNManager = () => {
        return is(ROLE_USER.DN_MANAGER.ID);
    }

    const isAccountant = () => {
        return is(ROLE_USER.ACCOUNTANT.ID);
    }

    const isSupport = () => {
        return is(ROLE_USER.SUPPORT.ID);
    }

    const isCustomer = () => {
        return is(ROLE_USER.CUSTOMER.ID);
    }

    const isEmployed = () => {
        return (
            isAdmin()
            || isDN()
            || isDNSales()
            || isDNManager()
            || isAccountant()
            || isSupport()
        )
    }

    const is = (_role) => {
        if (isSignIn()) {
            return user?.roleUserId === _role;
        }

        return false;
    }

    const getSessionToken = async () => {
        if (isSignIn()) {
            return await userAuth.getIdToken(true);
        }

        return undefined;
    }

    const reload = async () => {
        return await loadDataUser();
    }

    const getIndexPath = () => {
        if (isEmployed()) {
            return ROUTES.admin.index;
        } else if (isCustomer()) {
            return ROUTES.customer.index;
        } else {
            return ROUTES.login;
        }
    }

    return {
        data,
        signIn: signInAuth,
        signOut: signOutAuth,
        register,
        recoveryPassword,
        resetPassword,
        verifyHashResetPassword,
        isAdmin,
        isDN,
        isDNSales,
        isDNManager,
        isAccountant,
        isSupport,
        isCustomer,
        isEmployed,
        isSignIn,
        getSessionToken,
        reload,
        getIndexPath,
        ...user
    }
}

export default useUserHook;