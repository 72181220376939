import axios from 'axios';

const request = async (_props) => {
    try {
        return await axios.request({
            url: _props.url,
            method: _props.method,
            data: _props.data,
            params: _props.params,
            responseType: _props.responseType,
            headers: _props.headers
        });
    } catch (error) {
        throw new handleError(error);
    }
};

request.defaultProps = {
    method: 'get',
    responseType: 'json'
}

class handleError {
    constructor(_error) {
        this.message = '';
        this.status = {
            code: 500
        };

        if (_error.response) {
            const response = _error.response;
            this.status.code = response.status;

            if (Array.isArray(response.data)) {
                this.message = response.data[0];
            } else if (typeof response.data === 'object') {
                if (response.data.error) {
                    this.message = response.data.errors || response.data.error;
                } else {
                    this.message = response.statusText;
                }
            } else {
                this.message = response.data;
            }
        } else if (_error.request) {
            if (_error.request.readyState === 4 && _error.request.status === 0) {
                this.message = 'Petición no permitida por el navegador';
            }
            else {
                this.message = 'Error desconocido';
            }
        } else {
            this.message = _error.message;
        }
    }
}

export default request;