import { DateTime } from "luxon";
import { useRef } from "react";
import NeoTable from "design/design_components/neo/table/NeoTable.base";
import NeoTableColumn from "design/design_components/neo/table/NeoTableColumn.base";

const AdminCustomersReviewsTableComponent = (_props) => {
    const tableRef = useRef(null);

    const column = {
        date: {
            body: (_rowData) => {
                const date = DateTime.fromISO(_rowData.date);
                const dateFormated = date.toFormat('yyyy-MM-dd');

                return (<>
                    <span className="p-column-title">Fecha</span>
                    {dateFormated}
                </>);
            }
        },

        reviewer: {
            body: (_rowData) => {
                return (<>
                    <span className="p-column-title">Responsable</span>
                    {_rowData.userReviewer.userDetail.name}
                </>);
            }
        },

        comment: {
            body: (_rowData) => {
                return (<>
                    <span className="p-column-title">Información adicional</span>
                    {_rowData.comment}
                </>);
            }
        },
    };

    return (<>
        <NeoTable
            value={_props.data}
            paginator
            rows={10}
            removableSort
            ref={tableRef}
            loading={_props.loading}
            emptyMessage="Sin datos"
            sortField="date"
            sortOrder={-1}
        >

            <NeoTableColumn
                field="date"
                header="Fecha"
                sortable
                body={column.date.body}
                filter
                filterMatchMode="contains"
            />

            <NeoTableColumn
                field="rfc"
                header="Responsable"
                sortable
                body={column.reviewer.body}
                filter
                filterMatchMode="contains"
            />

            <NeoTableColumn
                field="comment"
                header="Información adicional"
                sortable
                body={column.comment.body}
                filter
                filterMatchMode="contains"
            />

        </NeoTable>
    </>)
}

export default AdminCustomersReviewsTableComponent;