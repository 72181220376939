import NeoGridContainer from "design/design_components/neo/layout/NeoGridContainer.base";
import NeoSingleContainer from "design/design_components/neo/layout/NeoSingleContainer.base";
import NeoColumn from "design/design_components/neo/layout/NeoColumn.base";
import { useHistory, useLocation } from "react-router-dom";
import { useComponent, useGeneral } from "hooks";
import ROUTES from "routers/Routes.router.json";
import utils from "utils";
import { APP } from "constants";

const GeneralBase = (_props) => {
    const component = useComponent();
    const general = useGeneral();

    const history = useHistory();
    const location = useLocation();

    const ERRORS_SSO = {
        WITHOUT_SESSION: {
            code: 1,
            message: 'El atributo session es requerido'
        },
        WITHOUT_APP: {
            code: 2,
            message: 'El atributo app es requerido'
        },
    };

    component.useEffect(() => {
        const url = new URL(window.location);

        const paramApp = url.searchParams.get('app') || location.state?.app;
        if (paramApp) {
            general.data.app = paramApp;
            url.searchParams.delete('app');
        } else {
            general.data.app = APP.NEO_ADMIN.NAME;
        }

        const paramContinue = url.searchParams.get('continue') || location.state?.continue;
        if (paramContinue) {
            if (utils.validate.URL(paramContinue)) {
                general.data.continueUrl = paramContinue;
                url.searchParams.delete('continue');
            }
        } else {
            general.data.continueUrl = null;
        }

        const paramSession = url.searchParams.get('session') || location.state?.session;
        if (paramSession) {
            general.data.sessionId = paramSession;
            url.searchParams.delete('session');
        } else {
            general.data.sessionId = null;
        }

        // whihout param session
        if (general.data.continueUrl && !general.data.sessionId) {
            const continueUrl = new URL(general.data.continueUrl);
            continueUrl.searchParams.set('error', JSON.stringify(ERRORS_SSO.WITHOUT_SESSION));
            window.location.replace(continueUrl);
        }

        // param app invalid
        else if (general.data.continueUrl && general.data.app) {
            if (!Object.values(APP).map(app => app.NAME).includes(general.data.app)) {
                const continueUrl = new URL(general.data.continueUrl);
                continueUrl.searchParams.set('error', JSON.stringify(ERRORS_SSO.WITHOUT_APP));
                window.location.replace(continueUrl);
            }
        }

        window.history.replaceState(null, null, url);
    }, [])

    component.useEffect(() => {
        switch (general.data.app) {
            case APP.NEO_VALIDADOR.NAME:
                import('design/assets/img/mnv/logo-bw.svg').then((logo) => {
                    general.data.logo = logo.default;
                });
                import('design/assets/styles/mnv/_utilities.module.scss').then((utilities) => {
                    general.data.classBGColor = utilities.default['bg-color-main'];
                });
                break;
            case APP.NEO_WIFI.NAME:
                import('design/assets/img/wfi/logo.svg').then((logo) => {
                    general.data.logo = logo.default;
                });
                import('design/assets/styles/wfi/_utilities.module.scss').then((utilities) => {
                    general.data.classBGColor = utilities.default['bg-color-main'];
                });
                break;
            case APP.NEO_CONECTA.NAME:
                import('design/assets/img/cta/logo-bw.svg').then((logo) => {
                    general.data.logo = logo.default;
                });
                import('design/assets/styles/cta/_utilities.module.scss').then((utilities) => {
                    general.data.classBGColor = utilities.default['bg-color-main'];
                });
                break;
            case APP.NEO_ADMIN.NAME:
                import('design/assets/img/adm/logo-bw.png').then((logo) => {
                    general.data.logo = logo.default;
                });
                import('design/assets/styles/adm/_utilities.module.scss').then((utilities) => {
                    general.data.classBGColor = utilities.default['bg-color-main'];
                });
                break;
            default:
                break;
        }
    }, [general.data.app])

    return (<>
        {(general.data.classBGColor && general.data.logo) && (
            <NeoSingleContainer extra={general.data.classBGColor}>
                <NeoGridContainer col="12" subExtra="h-100">
                    <NeoColumn col="12" extra="px-0">
                        <a href="#logo" onClick={() => {
                            history.push(ROUTES.index)
                        }}>
                            <img className="logo-intro" src={general.data.logo} alt={general.data.app} />
                        </a>
                    </NeoColumn>
                    <NeoColumn md="6" extra="p-md-offset-3 ">
                        {_props.children}
                    </NeoColumn>
                </NeoGridContainer>
            </NeoSingleContainer>
        )}
    </>)
}

export default GeneralBase;